/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { TicketBoxCollapsable } from "components/ticket-box-collapsable/TicketBoxCollapsable";
import { TicketV1Attributes } from "typing/dto";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import styles from "./TicketEditRefundRequestInfo.module.scss";
import isNil from "lodash/isNil";
import { DispositionEntity } from "entities/Disposition";

interface TicketEditRefundRequestInfoProps {
    ticketAttr: TicketV1Attributes;
}

export const TicketEditRefundRequestInfo: React.FC<TicketEditRefundRequestInfoProps> = ({ticketAttr}) => {
    const dispositionFirstLevel = useSelector((state: RootState) => state.ticketEditInfo.dispositionFirstLevel);
    const dispositionSecondLevel = useSelector((state: RootState) => state.ticketEditInfo.dispositionSecondLevel);
    const dispositionThirdLevel = useSelector((state: RootState) => state.ticketEditInfo.dispositionThirdLevel);
    
    const dispositionEntity = new DispositionEntity(
        dispositionFirstLevel,
        dispositionSecondLevel,
        dispositionThirdLevel
    );
    const isGuestRefundRequest = DispositionEntity.isGuestRefundRequest(ticketAttr.disposition_id);
    const isOwnerRefundRequest = dispositionEntity.isOwnerRefundRequest();

    // Do not display until to have information
    if (!isGuestRefundRequest && !isOwnerRefundRequest) {
        return (
            <></>
        );
    }

    const TicketEditRefundRequestInfoHeader = () => (
        <div data-testid="ticket-edit-refund-request-info-header" className={styles.header}>
            <h2>Check Refund Request</h2>
        </div>
    );

    const GuestRefundRequest = (ticketAttr: TicketV1Attributes) => (
        <div data-testid="guest-refund-request-body">
            <b>First Name: </b> <span>{ticketAttr.refund_request_first_name}</span> <br/>
            <b>Last Name: </b> <span>{ticketAttr.refund_request_last_name}</span> <br/>
            <b>Address: </b> <span>{ticketAttr.refund_request_address}</span> <br/>
            <b>City: </b> <span>{ticketAttr.refund_request_city}</span> <br/>
            <b>Zip: </b> <span>{ticketAttr.refund_request_zip}</span> <br/>
            <b>Total Amount: </b> <span>{!isNil(ticketAttr.refund_request_total_amount) && `$${ticketAttr.refund_request_total_amount}`}</span> <br/>
            <b>Rent Amount: </b> <span>{!isNil(ticketAttr.refund_request_rent_amount) && `$${ticketAttr.refund_request_rent_amount}`}</span> <br/>
        </div>
    );

    const OwnerRefundRequest = (ticketAttr: TicketV1Attributes) => (
        <div data-testid="owner-refund-request-body">
            <b>Amount: </b> <span>{!isNil(ticketAttr.refund_request_total_amount) && `$${ticketAttr.refund_request_total_amount}`}</span>
        </div>
    );

    return (
        <TicketBoxCollapsable header={<TicketEditRefundRequestInfoHeader />}>
            {isGuestRefundRequest && GuestRefundRequest(ticketAttr)}
            {!isGuestRefundRequest && isOwnerRefundRequest && OwnerRefundRequest(ticketAttr)}
        </TicketBoxCollapsable>
    );
}
