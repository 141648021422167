import React from "react";
import styles from "./Header.module.scss";
import { ReactComponent as IconVacasaLogoHoz } from "../../assets/icons/vacasa-logo-horizontal.svg"
import { isFromAdminPage, isIframe } from "utils/helpers";
import { env } from "core/env";
import classNames from "classnames";

export const Header: React.FC = () => {

    // if within iframe we hide the header to save some space
    if (isIframe()) return <></>

    return (
        <div className={classNames(styles.header, 'no-print', { [styles["header-admin"]]: isFromAdminPage() })}>
            {isFromAdminPage() ? (
                <a href={env.REACT_APP_ADMIN_URL} target="_blank" rel="noopener noreferrer">
                    <IconVacasaLogoHoz data-testid="header-logo"/>
                </a>
            ) : (
                <IconVacasaLogoHoz data-testid="header-logo"/>
            )}
        </div>
    )
}
