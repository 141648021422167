import {ConnectService} from "./ConnectService";
import {ImplicitFlowService} from "./ImplicitFlowService";
import {
    FILE_ATTRIBUTE,
    FILES_ATTRIBUTE,
    TICKET_ADD_TIMES_IS_MAINTENANCE,
    TICKET_FETCH_REMINDER_IS_MAINTENANCE,
    TICKET_FETCH_REMINDER_UPCOMING,
    TicketInclude,
} from "appConstants";
import axiosInstance from "axios";
import {env} from "core/env";
import {HandleServiceError} from "decorators/service";
import isEmpty from "lodash/isEmpty";
import type {
    AssignableUserAttributes,
    DispositionV1Attributes,
    OwnerV1Attributes,
    PresignedDataAttributes,
    TicketAttachmentsV1Attributes,
    TicketCommentV1Attributes,
    TicketFollowerV1Attributes,
    TicketHistoryV1Attributes,
    TicketReminderV1Attributes,
    TicketTimesV1Attributes,
    TicketV1Attributes,
    TicketV1IncludedMap,
} from "typing/dto";
import type {
    ConfigOption,
    ConfigOptions,
    CustomRequestConfiguration,
    DataType,
    GetListResponse,
    GetResponse,
    GetTicketsResponse,
    PageQueryParams,
    PostAndPatchRequest,
    PostAndPatchResponse,
    QueryParams,
    TicketingServiceFilter,
} from "typing/request";

interface cacheType {
    dispositions: Array<DataType<DispositionV1Attributes>>;
}

/**
 * Ticketing Service V1
 */
export class TicketingService extends ImplicitFlowService {
    private static baseUrl = env.REACT_APP_TICKETING_SERVICE_URL;
    private static service: TicketingService;
    private connectService: ConnectService;
    private config: ConfigOptions | undefined;

    // in-memory cache
    private cache: cacheType = {
        dispositions: [],
    };

    constructor(connectService: ConnectService) {
        super(TicketingService.baseUrl);
        this.connectService = connectService;
    }

    /**
     * Get Singleton Instance
     */
    public static getInstance(): TicketingService {
        if (!TicketingService.service) {
            const connectService = ConnectService.getInstance();
            TicketingService.service = new TicketingService(connectService);
        }
        return TicketingService.service;
    }

    /**
     * Get Ticket Times by Ticket ID
     * @returns Not defined yet
     */
    @HandleServiceError()
    public async getTimes(ticketId: number, userId: number): Promise<GetResponse<TicketTimesV1Attributes[]>> {
        const axios = await this.axiosInstance();
        const {data: response} = await axios.get(`${this.apiUrl}/tickets/${ticketId}/hours`, {
            params: {
                ticketId,
                userId,
            },
            paramsSerializer: this.paramsSerializer,
        });
        response.data = response.data.map((times: any) => {
            times.attributes.id = times.id;
            return times.attributes;
        });
        return response?.data;
    }

    /**
     * Add Ticket Times by Ticket ID
     * @returns Not defined yet
     */
    @HandleServiceError()
    public async addTimes({
        ticketId,
        userId,
        note,
        fromTime,
        toTime,
        external,
    }: {
        ticketId: number;
        userId: number;
        note: string;
        fromTime: string;
        toTime: string;
        external: 0 | 1;
    }): Promise<PostAndPatchRequest<TicketTimesV1Attributes>> {
        const axios = await this.axiosInstance();
        const {data: response} = await axios.post(`${this.apiUrl}/tickets/${ticketId}/hours`, {
            data: {
                type: "ticket-hour",
                attributes: {
                    user_id: userId,
                    from: fromTime,
                    to: toTime,
                    note,
                    is_maintenance: TICKET_ADD_TIMES_IS_MAINTENANCE,
                    external,
                },
            },
        });
        response.data.attributes.id = response.data.id;
        return response?.data.attributes;
    }

    /**
     * Get Ticket Entity based on it's id
     * @returns
     */
    @HandleServiceError()
    public async getTicketById(ticketId: number, include?: TicketInclude[]): Promise<GetResponse<TicketV1Attributes, TicketV1IncludedMap>> {
        const axios = await this.axiosInstance();
        const {data: response} = await axios.get(`${this.apiUrl}/tickets/${ticketId}`, {
            params: isEmpty(include) ? {} : {include: include?.toString()},
            paramsSerializer: this.paramsSerializer,
        });
        response.data.attributes.id = response.data.id;
        return response;
    }

    /**
     * Gets all ticket entities by pagination and allowed filters:
     * @param object QueryParams
     * @param customRequestConfig CustomRequestConfiguration
     */
    @HandleServiceError()
    public async getTickets(
        {page, filter, sort, include}: QueryParams<TicketingServiceFilter>,
        customRequestConfig: CustomRequestConfiguration = {}
    ): Promise<GetTicketsResponse | void> {
        const axios = await this.axiosInstance(customRequestConfig);
        const response = await axios.get(`${this.apiUrl}/tickets`, {
            timeout: 35000,
            params: {
                page,
                filter,
                sort,
                include,
            },
            paramsSerializer: this.paramsSerializer,
        });
        return response?.data;
    }

    /**
     * Gets all assignee entities by pagination and allowed filters
     * @param page
     * @param filter
     * @param customRequestConfig
     * @returns
     */
    @HandleServiceError()
    public async getAssignees(
        filter?: TicketingServiceFilter,
        page?: PageQueryParams,
        customRequestConfig: CustomRequestConfiguration = {}
    ): Promise<GetListResponse<AssignableUserAttributes>> {
        const axios = await this.axiosInstance(customRequestConfig);
        const response = await axios.get(`${this.apiUrl}/users/assignable`, {
            params: {
                page,
                filter,
            },
            paramsSerializer: this.paramsSerializer,
        });
        return response?.data;
    }

    /**
     * Get Ticket Attachments based on it's Id
     * @param ticketId
     * @returns
     */
    @HandleServiceError()
    public async getAttachments(ticketId: number): Promise<GetResponse<TicketAttachmentsV1Attributes[]>> {
        const axios = await this.axiosInstance();
        const response = await axios.get(`${this.apiUrl}/tickets/${ticketId}/attachments`);
        return response?.data;
    }

    /**
     * Delete Ticket Attachments based on it's and the filename
     * @param ticketId
     * @param filename
     * @returns
     */
    @HandleServiceError()
    public async deleteAttachment(ticketId: number, filename: string): Promise<void> {
        const axios = await this.axiosInstance();
        await axios.delete(`${this.apiUrl}/tickets/${ticketId}/attachments`, {
            params: {
                attachmentNames: filename,
            },
            paramsSerializer: this.paramsSerializer,
        });
    }

    /**
     * Retrieves presigned URL data for uploading an attachment to an S3-compatible storage service.
     *
     * @param {number} ticketId - The ID of the ticket associated with the attachment.
     * @param {string} attachmentName - The name of the attachment file.
     * @param {string} fileType - The MIME type of the file.
     * @returns {Promise<PresignedDataAttributes>} A promise containing the presigned URL data, including the upload URL and required form fields.
     */
    @HandleServiceError()
    public async getPresignedData(ticketId: number, attachmentName: string, fileType: string): Promise<PresignedDataAttributes> {
        const axios = await this.axiosInstance();
        const response: {data: GetResponse<PresignedDataAttributes>} = await axios.get(
            `${this.apiUrl}/tickets/${ticketId}/attachments/presigned-post-policy`,
            {
                params: {attachmentName, contentType: fileType},
                paramsSerializer: this.paramsSerializer,
            }
        );
        return response?.data?.data.attributes;
    }

    /**
     * Uploads a file to an S3-compatible storage bucket using a presigned URL.
     *
     * @param {PresignedDataAttributes} data - The presigned URL data obtained from `getPresignedData()`.
     * @param {FormDataEntryValue} selectedFile - The file to be uploaded (as retrieved from `FormData`).
     * @returns {Promise<void>} A promise that resolves when the upload completes.
     */
    @HandleServiceError()
    public async postAttachmentToBucket(data: PresignedDataAttributes, selectedFile: FormDataEntryValue): Promise<void> {
        const {fields, url} = data;
        const parsedFields = JSON.parse(fields);
        const formData = new FormData();

        Object.keys(parsedFields).forEach((key) => formData.append(key, parsedFields[key]));
        formData.append(FILE_ATTRIBUTE, selectedFile);

        await axiosInstance.post(url, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    }

    /**
     * Handles multiple file uploads by obtaining presigned URLs and uploading the files concurrently.
     *
     * @param {number} ticketId - The ID of the ticket to associate with the uploaded files.
     * @param {FormData} formData - A `FormData` object containing the selected files.
     * @returns {Promise<void[]>} A promise that resolves when all files have been uploaded.
     * @throws {Error} If no files are selected.
     */
    @HandleServiceError()
    public async addAttachments(ticketId: number, formData: FormData): Promise<void[]> {
        const files = formData.getAll(FILES_ATTRIBUTE);
        if (!files.length) throw new Error("No files selected");

        const presignedDataPromises = files.map((file: FormDataEntryValue) => {
            if (!(file instanceof File)) throw new Error("Invalid file type");
            return this.getPresignedData(ticketId, file.name, file.type);
        });

        const presignedData = await Promise.all(presignedDataPromises);

        const attachmentPromises = presignedData.map((data: PresignedDataAttributes, index: number) =>
            this.postAttachmentToBucket(data, files[index])
        );

        return Promise.all(attachmentPromises);
    }

    /**
     * Patches a ticket
     * @param ticketId
     * @param attributes
     * @returns
     */
    @HandleServiceError()
    public async patchTicket(ticketId: Number, attributes: Partial<TicketV1Attributes>): Promise<TicketV1Attributes> {
        const request: PostAndPatchRequest<Partial<TicketV1Attributes>> = {
            data: {
                attributes: {
                    ...attributes,
                    modified_by: this.currentUserId,
                },
                type: "ticket",
            },
        };
        const axios = await this.axiosInstance();
        const response: {data: PostAndPatchResponse<TicketV1Attributes>} = await axios.patch(`${this.apiUrl}/tickets/${ticketId}`, request);
        return response.data.data.attributes;
    }

    /**
     * Get Flat Dispositions
     */
    @HandleServiceError()
    public async getDispositions(): Promise<Array<DataType<DispositionV1Attributes>>> {
        // return from cache if found
        if (this.cache.dispositions.length) return this.cache.dispositions;
        const axios = await this.axiosInstance();
        const response = await axios.get(`${this.apiUrl}/dispositions`);
        this.cache.dispositions = response?.data.data;
        return response?.data.data;
    }

    /**
     * Get Dispositions By id
     * @param dispositionId
     */
    @HandleServiceError()
    public async getDispositionById(dispositionId: number): Promise<GetResponse<DispositionV1Attributes>> {
        const axios = await this.axiosInstance();
        const response = await axios.get(`${this.apiUrl}/dispositions/${dispositionId}`);
        return response?.data;
    }

    /**
     * Get all ticket reminders
     * @param userId
     * @param ticketId
     */
    @HandleServiceError()
    public async fetchReminders(userId: number, ticketId: number): Promise<GetListResponse<TicketReminderV1Attributes>> {
        const axios = await this.axiosInstance();
        const {data: response} = await axios.get(`${this.apiUrl}/tickets/reminders`, {
            params: {
                userId,
                ticketId,
                isMaintenance: TICKET_FETCH_REMINDER_IS_MAINTENANCE,
                upcoming: TICKET_FETCH_REMINDER_UPCOMING,
            },
            paramsSerializer: this.paramsSerializer,
        });
        response.data = response.data.map((reminder: any) => {
            reminder.attributes.id = reminder.id;
            return reminder.attributes;
        });
        return response?.data;
    }

    /**
     * Add a reminder to a ticket
     * @param ticketId
     * @param user_id
     * @param is_maintenance
     * @param display_date
     * @param message
     */
    @HandleServiceError()
    public async addReminder({
        ticket_id,
        user_id,
        is_maintenance,
        display_date,
        message,
    }: TicketReminderV1Attributes): Promise<PostAndPatchResponse<TicketReminderV1Attributes>> {
        const axios = await this.axiosInstance();
        const {data: response} = await axios.post(`${this.apiUrl}/tickets/reminders`, {
            data: {
                type: "ticket-reminder",
                attributes: {
                    ticket_id,
                    user_id,
                    is_maintenance,
                    display_date,
                    message,
                },
            },
        });
        response.data.attributes.id = response.data.id;
        return response?.data.attributes;
    }

    /**
     * Add a follower to a ticket
     * @param ticketId
     * @param followerId
     */
    @HandleServiceError()
    public async addFollower(ticketId: number, followerId: number): Promise<TicketFollowerV1Attributes> {
        const axios = await this.axiosInstance();
        const {data: response} = await axios.post(`${this.apiUrl}/tickets/${ticketId}/followers`, {
            data: {
                attributes: {
                    user_id: followerId,
                    is_maintenance: true,
                },
                type: "ticket-follower",
            },
        });
        response.data.attributes.id = response.data.id;
        return response?.data.attributes;
    }

    /**
     * Remove a ticket reminder
     * @param reminderId
     */
    @HandleServiceError()
    public async removeReminder(reminderId: number): Promise<void> {
        const axios = await this.axiosInstance();
        await axios.delete(`${this.apiUrl}/tickets/reminders/${reminderId}`);
    }

    /**
     * Remove a follower to a ticket
     * @param ticketId
     * @param followerId
     */
    @HandleServiceError()
    public async removeFollower(ticketId: number, followerId: number): Promise<void> {
        const axios = await this.axiosInstance();
        return await axios.delete(`${this.apiUrl}/tickets/${ticketId}/followers`, {
            data: {
                data: {
                    attributes: {
                        user_id: followerId,
                    },
                    type: "ticket-follower",
                },
            },
        });
    }

    /**
     * Adds a comment
     * @param attributes
     */
    @HandleServiceError()
    public async addComment(
        attributes: Pick<TicketCommentV1Attributes, "external" | "note" | "ticket_id">
    ): Promise<GetResponse<TicketCommentV1Attributes>> {
        const request: PostAndPatchRequest<TicketCommentV1Attributes> = {
            data: {
                attributes: {
                    ...attributes,
                    timestamp: new Date().toISOString(),
                    user_id: this.currentUserId,
                },
                type: "ticket-comment",
            },
        };
        const axios = await this.axiosInstance();
        const response = await axios.post(`${this.apiUrl}/tickets/comments`, request);
        return response?.data;
    }

    /**
     * Get history
     * @param filter
     * @param page
     */
    @HandleServiceError()
    public async getHistory(filter?: TicketingServiceFilter, page?: PageQueryParams): Promise<GetListResponse<TicketHistoryV1Attributes>> {
        const axios = await this.axiosInstance();
        const response = await axios.get(`${this.apiUrl}/tickets/history`, {
            params: {
                page,
                filter,
            },
            paramsSerializer: this.paramsSerializer,
        });
        return response?.data;
    }

    /**
     * Get comments
     * @param filter
     * @param page
     */
    @HandleServiceError()
    public async getComments(filter?: TicketingServiceFilter, page?: PageQueryParams): Promise<GetListResponse<TicketCommentV1Attributes>> {
        const axios = await this.axiosInstance();
        const response = await axios.get(`${this.apiUrl}/tickets/comments`, {
            params: {
                page,
                filter,
            },
            paramsSerializer: this.paramsSerializer,
        });
        return response?.data;
    }

    /**
     * Get owners
     * @param filter
     * @param page
     */
    @HandleServiceError()
    public async getOwners(filter?: TicketingServiceFilter, page?: PageQueryParams): Promise<GetListResponse<OwnerV1Attributes>> {
        const axios = await this.axiosInstance();
        const response = await axios.get(`${this.apiUrl}/owners`, {
            params: {
                page,
                filter,
            },
            paramsSerializer: this.paramsSerializer,
        });
        return response?.data;
    }

    get currentUserId(): number {
        return this.connectService.currentUserId;
    }

    public async loadConfig(): Promise<void> {
        if (this.config) return;
        try {
            const response = await fetch(env.TICKETING_CONFIG_FILE);
            this.config = await response.json();
        } catch (e) {
            console.error("Failed to fetch data, possibly due to a network issue.", e);
        }
    }

    public getConfig(configName: keyof ConfigOptions): ConfigOption[] | undefined;
    public getConfig(): ConfigOptions;
    public getConfig(configName?: keyof ConfigOptions): ConfigOption[] | ConfigOptions | undefined {
        return configName ? this.config?.[configName] : this.config;
    }

    public setConfig(config: ConfigOptions): void {
        this.config = config;
    }
}

export default TicketingService;
