import {DispositionsEnum} from "appConstants";
import {DispositionV1Attributes} from "typing/dto";
import {DataType} from "typing/request";

export class DispositionEntity {
    firstLevel: DataType<DispositionV1Attributes> | null;
    secondLevel: DataType<DispositionV1Attributes> | null;
    thirdLevel: DataType<DispositionV1Attributes> | null;

    public static isGuestRefundRequest(dispositionId: number) {
        return dispositionId === DispositionsEnum.GUEST_REFUND_CHECK_REQUEST;
    }

    constructor(
        firstLevel: DataType<DispositionV1Attributes> | null,
        secondLevel: DataType<DispositionV1Attributes> | null,
        thirdLevel: DataType<DispositionV1Attributes> | null
    ) {
        this.firstLevel = firstLevel;
        this.secondLevel = secondLevel;
        this.thirdLevel = thirdLevel;
    }

    public getDispositions() {
        return [this.firstLevel, this.secondLevel, this.thirdLevel].map((disposition) => {
            if (disposition === null) return null;
            return Number(disposition?.id);
        });
    }

    public isOwnerRefundRequest() {
        return this.getDispositions().includes(DispositionsEnum.OWNER_CREDITS);
    }
}
