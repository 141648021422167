import React from "react";
import Moment from "react-moment";
import styles from "./TicketSearchTableSlim.module.scss";
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow} from "@material-ui/core";
import { TableSortLabel } from 'components/table-sort-label/TableSortLabel';
import { useSelector } from "react-redux";
import { useAppDispatch } from "store/store";
import { setPage, setReload } from "store/ticketSearchSlice";
import type { RootState } from "store/store";
import type { BaseSyntheticEvent } from "react";
import type { MetaPagination } from "typing/request";
import type { UserConnectAttributes } from "typing/dto";
import { TicketEntity } from "entities/ticket";
import { LIST_DATE_FORMAT, SearchOrderKeysEnum, TicketListDefaultEnum } from "appConstants";
import { ExtraTextTooltip } from "components/extra-text-tooltip/ExtraTextTooltip";
import { TicketSearchCommentTooltip } from "components/ticket-search-comment-tooltip/TicketSearchCommentTooltip";
import { goToTicket } from "utils/helpers";
import { TicketSearchRow as TicketSearchRowBase } from "typing";

interface TicketSearchTableSlimProps {}

interface TicketSearchRow extends TicketSearchRowBase{
    severity: string,
    completedAt: string,
    completedBy: string,
 }

export const TicketSearchTableSlim: React.FC<TicketSearchTableSlimProps> = () => {
    const dispatch = useAppDispatch();
    const dateFormat = LIST_DATE_FORMAT;
    const usernameMaxLength = 25;
    const titleMaxLength = 50;

    const ticketList = useSelector(
        (state: RootState) => state.ticketSearch.tickets
    );
    const pagination: MetaPagination | null = useSelector(
        (state: RootState) => state.ticketSearch.pagination
    );
    const users: UserConnectAttributes[] = useSelector(
        (state: RootState) => state.ticketSearch.users
    );

    const getUserName = (userId: number) => users
        .filter((user:UserConnectAttributes) => Number(user.id) === Number(userId))
        .map((user:UserConnectAttributes) => `${user.first_name} ${user.last_name}`)
        .join();

    const rows: TicketSearchRow[] = ticketList ?
        ticketList.map(ticket => {
            const {attributes, included, includedMap} = ticket;
            const lastComment = included?.length && includedMap?.comments ? includedMap.comments[0] : null;
            return {
                id: ticket.id,
                status: attributes.display_status,
                title: attributes.title,
                severity: TicketEntity.severityOptions.find(severity => severity.value === attributes.severity)?.name,
                assignedTo: getUserName(attributes.assigned_to),
                createdAt: attributes.creation_date,
                completedAt: attributes.completed_date,
                completedBy: getUserName(attributes.assigned_to), // TODO: Replace it with completed_by
                lastComment: lastComment?.attributes,
            } as TicketSearchRow;
        }): [];

    const rowsPerPageOptions = [10, 25, 100];
    const page = pagination?.current_page ? pagination?.current_page - 1 : 0;
    const rowsPerPage = pagination?.per_page ? Number(pagination.per_page) : 20;
    const count = pagination?.total ?? 0;

    const handleChangePage = (_event: any, page: number) => {
        const currentPage = page + 1;
        dispatch(setPage({
            limit: Number(pagination?.per_page),
            number: currentPage
        }));
        dispatch(setReload());
    };
    const handleChangeRowsPerPage = (event: BaseSyntheticEvent) => {
        const pageLimit: string = event.target.value;
        dispatch(setPage({
            limit: pageLimit,
            number: pagination?.current_page
        }));
        dispatch(setReload());
    };

    return ( 
        <section data-testid="ticket-search-table-slim" className={styles.content}>
            <TableContainer>
                <Table>
                    <TableHead data-testid="ticket-search-table-slim-header">
                        <TableRow key="header">
                            <TableCell style={{ width: '10%' }} align="left">
                                <TableSortLabel shouldSetQueryParams={false} sortProperty={SearchOrderKeysEnum.STATUS}>Status</TableSortLabel>
                            </TableCell>
                            <TableCell style={{ width: '20%' }}>
                                <TableSortLabel shouldSetQueryParams={false} sortProperty={SearchOrderKeysEnum.TITLE}>Title</TableSortLabel>
                            </TableCell>
                            <TableCell style={{ width: '10%' }}>
                                <TableSortLabel shouldSetQueryParams={false} sortProperty={SearchOrderKeysEnum.SEVERITY}>Severity</TableSortLabel>
                            </TableCell>
                            <TableCell style={{ width: '10%' }}>Assigned To</TableCell>
                            <TableCell style={{ width: '10%', position: 'relative'}}>
                                <TableSortLabel shouldSetQueryParams={false} sortProperty={SearchOrderKeysEnum.CREATION_DATE}>Created at</TableSortLabel>
                            </TableCell>
                            <TableCell style={{ width: '10%' }}>
                                <TableSortLabel shouldSetQueryParams={false} sortProperty={SearchOrderKeysEnum.COMPLETED_DATE}>Completed at</TableSortLabel>
                            </TableCell>
                            <TableCell style={{ width: '10%' }}>Completed by</TableCell>
                            <TableCell style={{ width: '20%' }}>Last comment</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {
                    rows.length ? (rows.map((row: TicketSearchRow) => (
                        <TableRow key={row.id} onClick={() => {goToTicket(row)}}>
                            <TableCell style={{ width: '10%' }}>{row.status}</TableCell>
                            <TableCell style={{ width: '20%' }}>
                                <ExtraTextTooltip text={row.title} maxLength={titleMaxLength}/>
                            </TableCell>
                            <TableCell style={{ width: '10%' }}>{row.severity || TicketListDefaultEnum.SEVERITY}</TableCell>
                            <TableCell style={{ width: '10%' }}>
                                {
                                    row.assignedTo ?
                                    <ExtraTextTooltip text={row.assignedTo} maxLength={usernameMaxLength}/> :
                                    TicketListDefaultEnum.ASSIGNED_TO
                                }
                            </TableCell>
                            <TableCell style={{ width: '10%' }}>
                                <Moment className="uppercase" format={dateFormat}>{row.createdAt}</Moment>
                            </TableCell>
                            <TableCell style={{ width: '10%' }}>
                                {
                                    row.completedAt ?
                                    <Moment className="uppercase" format={dateFormat}>{row.completedAt}</Moment> :
                                    TicketListDefaultEnum.COMPLETED_AT
                                }
                            </TableCell>
                            <TableCell style={{ width: '10%' }}>
                                {
                                    row.completedAt ?
                                    <ExtraTextTooltip text={row.completedBy} maxLength={usernameMaxLength}/> :
                                    TicketListDefaultEnum.COMPLETED_AT
                                }
                            </TableCell>
                            <TableCell style={{ width: '20%' }}>
                                {
                                    row.lastComment ? 
                                    <TicketSearchCommentTooltip comment={row.lastComment} username={getUserName(row.lastComment.user_id)} />: 
                                    TicketListDefaultEnum.COMMENTS
                                }
                            </TableCell>
                        </TableRow>
                    ))) : 
                        <TableRow>
                            <TableCell style={{ width: '100%' }} colSpan={10} align="center">
                                {TicketListDefaultEnum.TICKETS}
                            </TableCell>
                        </TableRow>
                    }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </section>
    );
}
