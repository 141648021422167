import React, {LegacyRef, useEffect, useState} from "react";
import styles from "./MultiSelectFilter.module.scss";
import Multiselect from "multiselect-react-dropdown";
import {ReactComponent as IconArrow} from "../../assets/icons/arrow.svg"
import {ReactComponent as IconTimes} from "../../assets/icons/times.svg"
import {datadogLogs} from "@datadog/browser-logs";
import {FilterOption} from "typing/request";
import useURLParams from 'hooks/useURLParams';
import useCallbackWithDebounce from "hooks/useCallbackWithDebounce";
import uniq from "lodash/uniq";
import {getSelectedFilterOptions} from "utils/filter-options";
import {ListFilterEnum} from "appConstants";

interface MultiSelectFilterProps {
    disable?: boolean;
    id?: string;
    minimumCharacters?: number;
    onFetchData?: (searchString: string) => Promise<FilterOption[] | void>;
    onUpdate: (selectedList: Record<string, FilterOption[]>) => void;
    options?: FilterOption[];
    placeholder?: string;
    setOptions?: React.Dispatch<React.SetStateAction<FilterOption[]>>;
    title?: string;
    selectedValues?: FilterOption[];
    urlParams?: boolean; 
    isGroupingEnabled?: boolean;
    defaultOptions?: FilterOption[];
    setSelectedValues?: React.Dispatch<React.SetStateAction<FilterOption[]>>;
}

export const MultiSelectFilter: React.FC<MultiSelectFilterProps> = ({
    disable,
    id = 'filter-id',
    minimumCharacters = 3,
    onFetchData,
    onUpdate,
    options = [],
    placeholder = 'Start to type...',
    setOptions,
    title,
    selectedValues = [],
    urlParams = true,
    isGroupingEnabled = false,
    defaultOptions = [],
    setSelectedValues
}: MultiSelectFilterProps) => {
    const [loading, setLoading] = useState<boolean>(false);

    const { setURLParams } = useURLParams();
    const multiselectRef:LegacyRef<Multiselect> = React.createRef();
    const [key, setKey] = useState(0);
    const valueMaxChars = 15;

    const clearOptions = (): void => setOptions && setOptions([]);

    const valueWrapper = (value: string): string => value.length > valueMaxChars ? value.substring(0, valueMaxChars) : value;

    /**
     * Updates the available filter options and triggers a re-render by toggling the key value.
     *
     * @param {FilterOption[]} newOptions - An array of new filter options to be displayed or updated.
     * @returns {void} - This function does not return a value.
     */
    const renderOptions = (newOptions: FilterOption[]): void => {
        // If the setOptions function is not available, exit early
        if(!setOptions) return;
        
        // Update the options using the provided setter function
        setOptions(newOptions);

        // Trigger a re-render by toggling the key between 0 and 1
        setKey(key === 0 ? 1 : 0);
    };

    const updateResults = (selectedList: FilterOption[]): void => {
        clearOptions();
        const selValues: string = uniq(
            selectedList.map((item: FilterOption) => Array.isArray(item.value) ? item.value : [item.value]).flat()
        ).join(',');
        if (urlParams) setURLParams({[`filter[${id}]`]: selValues}, ![ListFilterEnum.MANAGER_ID, ListFilterEnum.STATUS].some((filterId) => filterId === id));
        // Updates the selected list based on grouping logic if grouping is enabled
        selectedList = isGroupingEnabled ? groupOptions(selectedList) : selectedList;
        onUpdate({[id]: selectedList});
        if (setSelectedValues) setSelectedValues(selectedList);
    };

    /**
     * Groups the selected filter options, updates the available options if necessary,
     * and returns the updated list of selected filter options.
     *
     * @param {FilterOption[]} selectedList - An array of currently selected filter options.
     * @returns {FilterOption[]} - An updated array of selected filter options, including both 
     *                             individual options and fully selected groups.
     */
    const groupOptions = (selectedList: FilterOption[]): FilterOption[] => {
        // Extract and flatten the values of all selected filter groups
        const selectedGroupValues = selectedList
            .map(options => options.value)
            .flat()
            .map(String);

        // Update the selected list based on the group values
        selectedList = getSelectedFilterOptions(selectedGroupValues, defaultOptions);

        // Filter out the options that are already part of selected groups
        const newOptions = defaultOptions.filter((option:FilterOption) => !selectedGroupValues.includes(String(option.value)));
        
        // Update the UI
        if (!onFetchData && setOptions) renderOptions(newOptions);
        
        // Return the updated list of selected options
        return selectedList
    };

    const search = useCallbackWithDebounce(async (searchString: string): Promise<void> => {
        if (searchString.length < minimumCharacters) return;
        setLoading(true);
        try {
            if (!onFetchData) return;
            const results: void | FilterOption[] = await onFetchData(searchString);
            if (results && setOptions) setOptions(results);
        } catch(e) {
            if (!(e instanceof Error)) throw e;
            datadogLogs.logger.error("MultiSelectFilter.search", {}, e);
        } finally {
            setLoading(false);
        }
    });

    useEffect(() => {
        if (!selectedValues.length || !isGroupingEnabled) return;
        // Processes the selected values to group options
        const selectedList = isGroupingEnabled ? groupOptions(selectedValues) : selectedValues;
        // Updates the selected list if it has changed
        if (selectedValues.length === selectedList.length) return;
        onUpdate({[id]: selectedList});
        if (setSelectedValues) setSelectedValues(selectedList);
    },[selectedValues]); // eslint-disable-line react-hooks/exhaustive-deps 

    return ( 
        <div className={styles.content}>
            { title && <h4 data-testid="multi-select-filter-title" className={styles["filter-title"]}>{title}</h4>}
            <Multiselect
                key={key}
                placeholder={placeholder}
                options={options}
                displayValue="name"
                showArrow={true}
                customArrow={<IconArrow />}
                customCloseIcon={<IconTimes />}
                id={id}
                onSelect={updateResults}
                onRemove={updateResults}
                onSearch={search}
                loading={loading}
                ref={multiselectRef}
                disable={disable}
                selectedValueDecorator={valueWrapper}
                selectedValues={selectedValues}
                hidePlaceholder
            />
        </div>
    );
}
