import React from "react";
import { Modal } from '@vacasa/react-components-lib';
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { removeReminder } from "store/ticketEditRemindersSlice";
import { LoaderButton } from "components/loader-button/LoaderButton";
import styles from "./ReminderRemoveModal.module.scss";


interface ReminderRemoveModalProps {
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    selectedReminder: any;
}


export const ReminderRemoveModal: React.FC<ReminderRemoveModalProps> = ({ setShowModal, selectedReminder }) => {
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

    const handleSubmit = async () => {
        await dispatch(removeReminder(selectedReminder.id));
        setShowModal(false)
    }

    return (
        <Modal showModal setShowModal={() => setShowModal(false)} size="medium">
            <div className="modal">
                <h1>Delete Reminder</h1>
                <div className={styles["reminder-modal-content"]}>
                    Delete the following reminder? You will not be able to recover it.
                    <p className="reminder-date">{selectedReminder.date}</p>
                    <p className="reminder-message">{selectedReminder.message}</p>
                </div>
                <div className="modal-actions">
                    <button
                        className="button button-primary"
                        onClick={() => setShowModal(false)}
                    >
                        Cancel
                    </button>
                    <LoaderButton
                        variant="primary-inverse"
                        onClick={handleSubmit}
                    >
                        Delete
                    </LoaderButton>
                </div>
            </div>
        </Modal>
    )
}
