import React, {useEffect} from "react";
import { TicketBoxCollapsable } from "components/ticket-box-collapsable/TicketBoxCollapsable";
import { RootState, useAppDispatch } from "store/store";
import { useSelector} from "react-redux";
import { fetchUnit } from "store/ticketEditUnitSlice";
import { AdminService } from "services/AdminService";
import { ConnectAccountManagerDetails, ConnectUnitManager, UnitV2Attributes } from "typing/dto"
import isEmpty from "lodash/isEmpty";

interface ITicketEditUnitProps {
    unitId?: number;
}

export const TicketEditUnit: React.FC<ITicketEditUnitProps> = ({unitId}) => {
    const dispatch = useAppDispatch();
    const unit = useSelector((state: RootState) => state.ticketEditUnit.unit);
    const connectUnit = useSelector((state: RootState) => state.ticketEditUnit.connectUnit);
    const isLoading = useSelector((state: RootState) => state.ticketEditUnit.isLoading);

    useEffect(() => {
        if (!unitId) return;
        dispatch(fetchUnit(unitId))
      }, [dispatch, unitId]);

    // Loading
    if (isLoading) return <TicketBoxCollapsable header={<h2 data-testid="ticket-edit-unit-is-loading">Unit</h2>}>Loading...</TicketBoxCollapsable>

    // Early return if no unit
    if (!unitId) return <TicketBoxCollapsable header={<h2 data-testid="ticket-edit-unit-not-found">Unit</h2>}>Not found</TicketBoxCollapsable>

    // Loading
    if (isLoading || !unit) return <TicketBoxCollapsable header={<h2>Unit</h2>}>Loading...</TicketBoxCollapsable>
    
    const Header = (
        <div data-testid="ticket-edit-unit-header">
            <h2>Unit</h2>
            <a href={AdminService.getUnitUrl(unit.legacy_unit_id)} target="_blank" rel="noreferrer">{unit?.unit_code}</a>
        </div>
    );

    const DisplayManager = ({manager}: {manager: ConnectUnitManager | undefined}) => (
        <>
            {
                !isEmpty(manager) ? (
                    <a href={AdminService.getUserTicketsUrl(manager.id)} target="_blank" rel="noreferrer">{manager.first_name} {manager.last_name}</a>
                ) : (
                    <>Not found</>
                )
            }
        </>
    )

    const DisplayUnitAccountManagerInfo = ({ contact }: {contact: ConnectAccountManagerDetails | undefined}) => {
        if(!contact) return;

        return (
            <>
                <b>Contact Information: </b>
                <ul>
                    <li><b>Name:</b> {`${contact.first_name} ${contact.last_name}`}</li>
                    <li><b>Email:</b> {contact.email}</li>
                    <li><b>Phone:</b> {contact.phone}</li>
                </ul>
            </>
        )
    }

    /**
     * Get Unit status from Unit Service Parameters
     * This was replicated from legacy logic with units from monolith and the `terminated` field in the unit.
     * The corresponding mapping between `terminated` field and `termination_status` field in Unit Service is below
     * terminated = -1 is the same as termination_status = 'pending'
     * terminated = 0 is the same as termination_status = 'not_terminated'
     * terminated = 1 is the same as termination_status = 'terminated'
     * @param unitAttributes
     */
    const getUnitStatus = (unitAttributes: UnitV2Attributes): string => {
        const isActive = unitAttributes.active_status === "active" ? "Active" : "Inactive";
        const isDisplayed = unitAttributes.display ? "Display" : "Do Not Display";

        switch (unitAttributes.termination_status) {
            case "terminated":
                return "Terminated";
            case "pending":
                return "Termination Pending";
            case "not_terminated":
                return `${isActive}; ${isDisplayed}`;
            default:
                return "Unknown Status";
        }
    };

    return ( 
        <TicketBoxCollapsable header={Header}>
            <div data-testid="ticket-edit-unit-body">
                <b>ID:</b> <a href={AdminService.getUnitUrl(unit.legacy_unit_id)} target="_blank" rel="noreferrer">{unit.legacy_unit_id}</a><br/>
                <b>Name:</b> <a href={AdminService.getUnitTicketsUrl(unit.legacy_unit_id)} target="_blank" rel="noreferrer">{unit.name}</a><br/>
                <b>Status:</b> {getUnitStatus(unit)}<br/>
                <b>Local Operations Manager: </b><DisplayManager manager={connectUnit?.local_operations_manager}/><br/>
                <b>Operations Manager: </b><DisplayManager manager={connectUnit?.general_manager}/><br/>
                <DisplayUnitAccountManagerInfo contact={connectUnit?.account_manager_details} />
            </div>
        </TicketBoxCollapsable>
    );
}
