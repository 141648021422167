import React, { useEffect, useState } from "react";
import { Modal, DatePicker } from "@vacasa/react-components-lib";
import styles from "./TicketEditInfoModal.module.scss";
import { Select, MenuItem , FormControl, TextField, Grid, InputLabel } from "@material-ui/core";
import { TicketEntity } from "entities/ticket";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useSelector, useDispatch} from "react-redux";
import { RootState } from "store/store";
import { Spinner } from "components/spinner/Spinner";
import {
    fetchModalInfo,
    setRecurrentOption,
    setFrequencyOfRecurrenceOption,
    setFrequencyOfRecurrenceOptionCustom,
    setReservationId,
    setReopenBasedOn,
    setRecurringBeforeDueDate,
    setSelectedTierOneOption,
    setSelectedTierTwoOption,
    setSelectedTierThreeOption,
    setSelectedFrequencyStartDate,
    setSelectedRequestedBy,
    patchTicketInfo,
    validateReservationIdAndDispositions,
    setValidationReservationErrorMsg,
    setGuestRefundData
} from "store/ticketEditInfoModalSlice";
import { LoaderButton } from "components/loader-button/LoaderButton";
import { muiMenuBottomProps } from "utils/helpers";
import { fetchTicket } from "store/ticketEditSlice";
import Alert from "@mui/material/Alert";
import isEmpty from "lodash/isEmpty";
import { US_STATES } from "appConstants";

interface TicketEditInfoModalProps{
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    ticketId: number;
    frequency: number | null;
    requestedBy: number | null;
    dispositionId: number;
    reservationId: number | null;
    unitId: number | null;
    reopenBasedOn: string | null;
    recurringBeforeDueDate: number | null;
    frequencyStartDate: string | null;
}

export const TicketEditInfoModal: React.FC<TicketEditInfoModalProps> = (props) => {

    const elementId = "ticket-edit-info-modal";
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const [fromTimepickerOpen, setFromTimepickerOpen] = useState(false);
    const validationResIdErrorMsg = useSelector((state: RootState) => state.ticketEditInfoModal.validationReservationErrorMsg);

    // Success & Errors
    const isLoadingFetch = useSelector((state: RootState) => state.ticketEditInfoModal.isLoadingFetch);

    // Disposition States
    const selectedTierOneOption = useSelector((state: RootState) => state.ticketEditInfoModal.selectedTierOneOption);
    const selectedTierTwoOption = useSelector((state: RootState) => state.ticketEditInfoModal.selectedTierTwoOption);
    const selectedTierThreeOption = useSelector((state: RootState) => state.ticketEditInfoModal.selectedTierThreeOption);
    const dispositionsTierOneOptions = useSelector((state: RootState) => state.ticketEditInfoModal.dispositionTierOneOptions);
    const dispositionsTierTwoOptions = useSelector((state: RootState) => state.ticketEditInfoModal.dispositionTierTwoOptions);
    const dispositionsTierThreeOptions = useSelector((state: RootState) => state.ticketEditInfoModal.dispositionTierThreeOptions);

    // Other Options
    const selectedRequestedBy = useSelector((state: RootState) => state.ticketEditInfoModal.selectedRequestedBy);
    const selectedRecurrenceOption = useSelector((state: RootState) => state.ticketEditInfoModal.selectedRecurrenceOption);
    const selectedFrequencyOfRecurrenceOption = useSelector((state: RootState) => state.ticketEditInfoModal.selectedFrequencyOfRecurrenceOption);
    const selectedReservationId = useSelector((state: RootState) => state.ticketEditInfoModal.selectedReservationId);
    const selectedReopenBasedOn = useSelector((state: RootState) => state.ticketEditInfoModal.selectedReopenBasedOn);
    const guestRefundData = useSelector((state: RootState) => state.ticketEditInfoModal.guestRefundData);
    const selectedRecurringBeforeDueDate = useSelector((state: RootState) => state.ticketEditInfoModal.selectedRecurringBeforeDueDate);
    const selectedFrequencyStartDate = useSelector((state: RootState) => state.ticketEditInfoModal.selectedFrequencyStartDate);
    const isCustomFrequencyOfRecurrence = useSelector((state: RootState) => state.ticketEditInfoModal.isCustomFrequencyOfRecurrence);
    const isCustomDueDate = useSelector((state: RootState) => state.ticketEditInfoModal.isCustomDueDate);
    const isCustomReopenBasedOn = useSelector((state: RootState) => state.ticketEditInfoModal.isCustomReopenBasedOn);

    // Validators, these are cheap to execute on every render, will keep them here in th meantime, consider moving to the store later
    const isInvalidDisposition = (dispositionsTierTwoOptions.length && !selectedTierTwoOption) || (dispositionsTierThreeOptions.length && !selectedTierThreeOption); 
    const isInvalidFrequencyOfRecurrence = selectedRecurrenceOption && !Number(selectedFrequencyOfRecurrenceOption);
    const isInvalidReopenBasedOn = selectedRecurrenceOption && (isCustomReopenBasedOn && !selectedFrequencyStartDate);
    const isInvalidReopenDueDate = selectedRecurrenceOption && (isCustomDueDate && !Number(selectedRecurringBeforeDueDate));
    const isGuestRefundSelected = selectedTierThreeOption === 60;
    const isInvalidRefundRequestFirstName = !guestRefundData.refundRequestFirstName;
    const isInvalidRefundRequestLastName = !guestRefundData.refundRequestLastName;
    const isInvalidRefundRequestAddress = !guestRefundData.refundRequestAddress;
    const isInvalidRefundRequestZip = !guestRefundData.refundRequestZip;
    const isInvalidRefundRequestTotalAmount = !guestRefundData.refundRequestTotalAmount;
    const isInvalidRefundRequest = [
        isInvalidRefundRequestFirstName,
        isInvalidRefundRequestLastName,
        isInvalidRefundRequestAddress,
        isInvalidRefundRequestZip,
        isInvalidRefundRequestTotalAmount,
    ];
    const isInvalid = [
        isInvalidDisposition,
        isInvalidFrequencyOfRecurrence,
        isInvalidReopenBasedOn,
        isInvalidReopenDueDate,
        ...(isGuestRefundSelected ? isInvalidRefundRequest : []),
    ].some(Boolean);

    // Guest Refund Check Request
    const refundRequestFirstName = useSelector((state: RootState) => state.ticketEdit.ticket?.refund_request_first_name);
    const refundRequestLastName = useSelector((state: RootState) => state.ticketEdit.ticket?.refund_request_last_name);
    const refundRequestAddress = useSelector((state: RootState) => state.ticketEdit.ticket?.refund_request_address);
    const refundRequestCity = useSelector((state: RootState) => state.ticketEdit.ticket?.refund_request_city);
    const refundRequestState = useSelector((state: RootState) => state.ticketEdit.ticket?.refund_request_state);
    const refundRequestZip = useSelector((state: RootState) => state.ticketEdit.ticket?.refund_request_zip);
    const refundRequestTotalAmount = useSelector((state: RootState) => state.ticketEdit.ticket?.refund_request_total_amount);
    const refundRequestRentAmount = useSelector((state: RootState) => state.ticketEdit.ticket?.refund_request_rent_amount);

    const DISALLOWED_NUMBER_INPUT_CHARS = ['e', '\'', '+'];
    /**
     * Prevents input of forbidden characters in number fields
     * @param e - Keyboard event from the input field
     */
    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (DISALLOWED_NUMBER_INPUT_CHARS.includes(e.key)) {
            e.preventDefault();
        }
    }

    /**
     * Saves The Ticket Updates
     */
    const handleSave = async () => {
        try {
            await dispatch(validateReservationIdAndDispositions({
                reservationId: selectedReservationId,
                unitId: props.unitId
            })).unwrap();
        } catch {
            return;
        }

        try {
            await dispatch(patchTicketInfo(props.ticketId)).unwrap();
            props.setIsModalOpen(false);
            await dispatch(fetchTicket(props.ticketId));
        } catch {
            props.setIsModalOpen(false);
        }
    }

    /**
     * Handle Reservation ID input changes
     */
    const handleReservationIdChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!isEmpty(validationResIdErrorMsg)) dispatch(setValidationReservationErrorMsg(""));
        dispatch(setReservationId(e.target.value))
    }

    /**
     * Handle input changes
     */
    const handleChange = (e: React.ChangeEvent<any>) => {
        dispatch(setGuestRefundData({...guestRefundData, [e.target.name]: e.target.value}));
    }

    /**
     * Fetches all the Required Data ( Dispositions Mostly ) and set Default Values
     */
    useEffect(() => {
       dispatch(fetchModalInfo(props.dispositionId));
       dispatch(setSelectedRequestedBy(props.requestedBy));
       dispatch(setRecurrentOption(TicketEntity.isRecurrent(props.frequency)));
       dispatch(setFrequencyOfRecurrenceOption(props.frequency));
       dispatch(setReservationId(props.reservationId));
       dispatch(setReopenBasedOn(props.reopenBasedOn));
       dispatch(setRecurringBeforeDueDate(props.recurringBeforeDueDate));
       dispatch(setSelectedFrequencyStartDate(props.frequencyStartDate));
    }, [
        dispatch,
        props.requestedBy,
        props.dispositionId,
        props.frequency,
        props.reservationId,
        props.reopenBasedOn,
        props.recurringBeforeDueDate,
        props.frequencyStartDate
    ]);

    useEffect(() => {
        if (isGuestRefundSelected) {
            dispatch(setGuestRefundData({
                refundRequestAddress,
                refundRequestCity,
                refundRequestFirstName,
                refundRequestLastName,
                refundRequestRentAmount,
                refundRequestState,
                refundRequestTotalAmount,
                refundRequestZip,
                validationResIdErrorMsg,
            }));
        } else {
            dispatch(setGuestRefundData({}));
        }
    }, [selectedTierThreeOption]); // eslint-disable-line react-hooks/exhaustive-deps

    // Spinner in case we are fetching dispositions
    if (isLoadingFetch) {
        return (
            <Modal showModal={true} canExit={true} setShowModal={props.setIsModalOpen} size="small">
                <div className={styles.modal}>
                    <h4>Edit Ticket Information</h4><br/><br/>
                    <div style={{textAlign: "center"}}>
                        <Spinner />
                    </div>
                </div>
            </Modal>
        );
    }

    return (
        <div className="modal">
            <Modal showModal={true} canExit={true} setShowModal={props.setIsModalOpen} size="small">
                <div className={styles.modal} data-testid="ticket-edit-info-modal" id={elementId}>
                    <h4>Edit Ticket Information</h4><br/>
                    <div className={styles["split-flex"]}>

                        {/* Requested By Edit */}
                        <div>
                            <h5>Requested By</h5>
                            <FormControl fullWidth>
                                <Select
                                    MenuProps={muiMenuBottomProps}
                                    value={selectedRequestedBy}
                                    inputProps={{"data-testid": "requested-by"}}
                                    onChange={(e) => dispatch(setSelectedRequestedBy(e.target.value))}
                                >
                                    { Object.keys(TicketEntity.requestedByOptions).map( (requestedById: string) => // @ts-ignore
                                        <MenuItem key={requestedById} value={requestedById}>{TicketEntity.requestedByOptions[requestedById]}</MenuItem>
                                    )}
                                </Select>
                            </FormControl><br/>
                        </div>
                        
                        {/* Dispositions Edit */}
                        <div>
                            <h5>Reservation ID</h5>
                            <TextField
                                value={selectedReservationId ?? ""}
                                onChange={handleReservationIdChange}
                                variant="outlined"
                                error={!isEmpty(validationResIdErrorMsg)}
                                inputProps={{
                                    "data-testid": "reservation-id",
                                    "className": styles["padding-thin"]
                                }}
                                fullWidth/>
                        </div>
                    </div>
                    <br/>

                    {/* Dispositions Edit */}
                    <h5>Disposition</h5>

                    {/* Tier One */}
                    <FormControl fullWidth>
                        <Select
                            value={selectedTierOneOption}
                            label="Disposition"
                            MenuProps={muiMenuBottomProps}
                            inputProps={{"data-testid": "disposition-tier-one"}}
                            onChange={(event) => dispatch(setSelectedTierOneOption(event.target.value))}
                        >
                            { dispositionsTierOneOptions.map( (disposition) => 
                                <MenuItem
                                    key={disposition.id}
                                    value={disposition.id}
                                    >
                                        {disposition.attributes.name}
                                </MenuItem>
                            )}
                        </Select>
                    </FormControl>

                    {/* Tier Two */}
                    { dispositionsTierTwoOptions.length && (
                        <FormControl fullWidth>
                            <Select
                                value={selectedTierTwoOption}
                                MenuProps={muiMenuBottomProps}
                                inputProps={{"data-testid": "disposition-tier-two"}}
                                onChange={(event) => dispatch(setSelectedTierTwoOption(event.target.value))}
                            >
                                { dispositionsTierTwoOptions.map( (disposition) => 
                                    <MenuItem key={disposition.id} value={disposition.id}>{disposition.attributes.name}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    )}

                    {/* Tier Three */}
                    { Boolean(dispositionsTierThreeOptions.length) && (
                        <FormControl fullWidth>
                            <Select
                                value={selectedTierThreeOption}
                                MenuProps={muiMenuBottomProps}
                                inputProps={{"data-testid": "disposition-tier-three"}}
                                onChange={(event) => dispatch(setSelectedTierThreeOption(event.target.value))}
                            >
                                { dispositionsTierThreeOptions.map( (disposition) => 
                                    <MenuItem key={disposition.id} value={disposition.id}>{disposition.attributes.name}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    )}
                    <br/><br/>

                    {/* Update Guest Refund */}
                    {isGuestRefundSelected && (<Grid container spacing={1} justifyContent="space-between">
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <TextField
                                    label="First Name*"
                                    value={guestRefundData["refundRequestFirstName"] ?? ""}
                                    variant="outlined"
                                    size="small"
                                    inputProps={{ "className": styles["padding-thin"] }}
                                    onChange={handleChange}
                                    name="refundRequestFirstName"
                                    error={isInvalidRefundRequestFirstName}
                                    helperText={isInvalidRefundRequestFirstName ? "Required" : ""}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <TextField
                                    label="Last Name*"
                                    value={guestRefundData["refundRequestLastName"] ?? ""}
                                    variant="outlined"
                                    size="small"
                                    inputProps={{ "className": styles["padding-thin"] }}
                                    onChange={handleChange}
                                    name="refundRequestLastName"
                                    error={isInvalidRefundRequestLastName}
                                    helperText={isInvalidRefundRequestLastName ? "Required" : ""}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    label="Street Address*"
                                    value={guestRefundData["refundRequestAddress"] ?? ""}
                                    variant="outlined"
                                    size="small"
                                    inputProps={{ "className": styles["padding-thin"] }}
                                    onChange={handleChange}
                                    name="refundRequestAddress"
                                    error={isInvalidRefundRequestAddress}
                                    helperText={isInvalidRefundRequestAddress ? "Required" : ""}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    label="City"
                                    value={guestRefundData["refundRequestCity"] ?? ""}
                                    variant="outlined"
                                    size="small"
                                    inputProps={{ "className": styles["padding-thin"] }}
                                    onChange={handleChange}
                                    name="refundRequestCity"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <FormControl variant="outlined" size="small" fullWidth>
                                    <InputLabel id="demo-simple-select-label">State</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        label="State"
                                        value={guestRefundData["refundRequestState"] ?? ""}
                                        onChange={handleChange}
                                        inputProps={{ "className": styles["padding-thin"] }}
                                        name="refundRequestState"
                                    >
                                        {(US_STATES as any).map((obj: Record<string, string>) => {
                                            const [[key, value]] = Object.entries(obj);

                                            return (<MenuItem key={key} value={key}>
                                                {value}
                                            </MenuItem>)
                                        })}
                                    </Select>
                                </FormControl>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <TextField
                                    label="Zip Code*"
                                    value={guestRefundData["refundRequestZip"] ?? ""}
                                    variant="outlined"
                                    size="small"
                                    inputProps={{ "className": styles["padding-thin"] }}
                                    onKeyDown={handleKeyDown}
                                    onChange={handleChange}
                                    name="refundRequestZip"
                                    error={isInvalidRefundRequestZip}
                                    helperText={isInvalidRefundRequestZip ? "Required" : ""}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <TextField
                                    label="Total Refund Amount*"
                                    value={guestRefundData["refundRequestTotalAmount"] || ""}
                                    type="number"
                                    variant="outlined"
                                    size="small"
                                    inputProps={{ "className": styles["padding-thin"] }}
                                    onKeyDown={handleKeyDown}
                                    onChange={handleChange}
                                    name="refundRequestTotalAmount"
                                    error={isInvalidRefundRequestTotalAmount}
                                    helperText={isInvalidRefundRequestTotalAmount ? "Required" : ""}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <TextField
                                    label="Amount from Rent"
                                    value={guestRefundData["refundRequestRentAmount"] || ""}
                                    type="number"
                                    variant="outlined"
                                    size="small"
                                    inputProps={{ "className": styles["padding-thin"] }}
                                    onKeyDown={handleKeyDown}
                                    onChange={handleChange}
                                    name="refundRequestRentAmount"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    )}

                    {/* Ticket Recurrence */}
                    <h5>Recurring Ticket</h5>
                    <FormControl fullWidth>
                        <Select
                            value={String(Number(selectedRecurrenceOption))}
                            onChange={(event) => dispatch(setRecurrentOption(Boolean(Number(event.target.value))))}
                            inputProps={{"data-testid": "recurrence-option"}}
                            MenuProps={muiMenuBottomProps}
                        >
                            <MenuItem value={"0"}>Not Recurring</MenuItem>
                            <MenuItem value={"1"}>Recurring</MenuItem>
                        </Select>
                    </FormControl>
                    <br/><br/>

                    {/* Ticket Recurrence Configuration */}
                    { selectedRecurrenceOption && (
                        <>
                            {/* Frequency Of Recurrence */}
                            <div className={ isCustomFrequencyOfRecurrence ? styles["split-flex"] : ""}>
                                <div>
                                    <h5>Frequency Of Recurrence</h5>
                                    <FormControl fullWidth>
                                        <Select
                                            MenuProps={muiMenuBottomProps}
                                            value={isCustomFrequencyOfRecurrence ? "0" : selectedFrequencyOfRecurrenceOption}
                                            onChange={(event) => dispatch(setFrequencyOfRecurrenceOption(event.target.value))}
                                            inputProps={{"data-testid": "frequency-of-recurrence"}}
                                        >
                                            { Object.keys(TicketEntity.frequencyOfRecurrence).map((id) => // @ts-ignore
                                                <MenuItem key={id} value={id}>{TicketEntity.frequencyOfRecurrence[id]}</MenuItem>
                                            )}
                                            <MenuItem value="0">Custom</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>

                                { isCustomFrequencyOfRecurrence && (
                                    <div>
                                        <h5>Recurring Frequency Days</h5>
                                        <TextField
                                            value={selectedFrequencyOfRecurrenceOption}
                                            type="number"
                                            variant="outlined"
                                            inputProps={{
                                                "data-testid": "due-days-after-reopen-date",
                                                "className": styles["padding-thin"]
                                            }}
                                            onChange={(event) => dispatch(setFrequencyOfRecurrenceOptionCustom(event.target.value))}
                                            fullWidth/>
                                    </div>
                                )}
                            </div>

                            {/* Recurrence Reopen Based On */}
                            <div className={ isCustomReopenBasedOn ? styles["split-flex"] : ""}>
                                <div>
                                    <h5>Reopen Based On</h5>
                                    <FormControl fullWidth>
                                        <Select
                                            MenuProps={muiMenuBottomProps}
                                            value={selectedReopenBasedOn}
                                            inputProps={{"data-testid": "reopen-based-on"}}
                                            onChange={(event) => dispatch(setReopenBasedOn(event.target.value))}
                                        >
                                            { Object.keys(TicketEntity.frequencyReopenTypes).map((id) => // @ts-ignore
                                                <MenuItem key={id} value={id}>{TicketEntity.frequencyReopenTypes[id]}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                { isCustomReopenBasedOn && (
                                    <div className={styles["start-time"]}>
                                        <h5>Start Date Of Recurrence</h5>
                                        <div className={styles["hidden-trigger"]} onClick={() => { setFromTimepickerOpen(true) }}></div>
                                        <DatePicker
                                            open={fromTimepickerOpen}
                                            value={selectedFrequencyStartDate}
                                            onClose={() => setFromTimepickerOpen(false)}
                                            onChange={(value) => { dispatch(setSelectedFrequencyStartDate(String(value)))}}
                                            format="MM/dd/yyyy"
                                            variant="inline"
                                            disableToolbar={true}
                                            />
                                    </div>
                                )}
                            </div>

                            {/* Recurrence Open After Due Date */}
                            <div className={ isCustomDueDate ? styles["split-flex"] : ""}>
                                <div className={ styles.duedate }>
                                    <h5>New Due Date</h5>
                                    <FormControl fullWidth>
                                        <Select
                                            MenuProps={muiMenuBottomProps}
                                            value={ isCustomDueDate ? "0" : selectedRecurringBeforeDueDate}
                                            inputProps={{"data-testid": "new-due-date",}}
                                            onChange={ (event) => dispatch(setRecurringBeforeDueDate(event.target.value))}
                                        >
                                            { Object.keys(TicketEntity.frequencyReopenAfterDuedate).map((id) => // @ts-ignore
                                                <MenuItem key={id} value={id}>{TicketEntity.frequencyReopenAfterDuedate[id]}</MenuItem>
                                            )}
                                            <MenuItem value="0">Custom</MenuItem>
                                        </Select>
                                    </FormControl><br />
                                </div>

                                { isCustomDueDate && (
                                    <div>
                                        <h5>Due Days After Reopen Date</h5>
                                        <TextField
                                            value={selectedRecurringBeforeDueDate}
                                            variant="outlined"
                                            type="number"
                                            inputProps={{
                                                "data-testid": "custom-new-due-date",
                                                "className": styles["padding-thin"],
                                            }}
                                            onChange={(event) => dispatch(setRecurringBeforeDueDate(event.target.value))}
                                            fullWidth/>
                                    </div>
                                )}
                            </div>
                        </>
                    )}

                    {/* Reservation id validation error message */}
                    { !isEmpty(validationResIdErrorMsg) && (
                        <div>
                            <Alert severity="error">
                                <span data-testid="validation-res-id-error-msg">{validationResIdErrorMsg}</span>
                            </Alert>
                        </div>
                    )}

                    {/* Button Actions */}
                    <div className={styles.actions}>
                        <button
                            className="button button-primary mr10"
                            onClick={() => props.setIsModalOpen(false)}
                        >
                            Cancel
                        </button>
                        
                        <LoaderButton
                            id="update-button-submit"
                            disabled={isInvalid}
                            onClick={handleSave}
                            variant="primary-inverse"
                        >
                                Update
                       </LoaderButton>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
