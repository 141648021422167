import {ADVANCED_SEARCH_STRATEGY, SortOrderEnum} from "appConstants";
import isArray from "lodash/isArray";
import type {SearchOrderByQueryParams, SearchSelectedFilterValues, SearchSortOrder, SortingStrategy} from "typing/request";

export class SortingQueryParamsAdapter {
    private static adapter: SortingQueryParamsAdapter | null;
    private _strategy: SortingStrategy = ADVANCED_SEARCH_STRATEGY;

    get strategy() {
        return this._strategy;
    }

    set strategy(strategy: SortingStrategy) {
        this._strategy = strategy;
    }

    /**
     * Get Singleton Instance
     */
    public static getInstance(): SortingQueryParamsAdapter {
        if (!SortingQueryParamsAdapter.adapter) SortingQueryParamsAdapter.adapter = new SortingQueryParamsAdapter();
        return SortingQueryParamsAdapter.adapter;
    }

    /**
     * Adapts the sorting query parameters by applying all relevant handlers.
     *
     * @returns The adapted sorting query parameters.
     */
    adapt(sortingQueryParams: string | null, orderByState: SearchOrderByQueryParams) {
        const result = sortingQueryParams !== null ? this._handleSortFromQueryParams(sortingQueryParams) : this._handleSortFromState(orderByState);
        return SortingQueryParamsAdapter.convertSortToString(result);
    }

    /**
     * It takes a sorting string presentation (e.g. `"-severity,status"`) and converts it
     * to an array of [key, value] (e.g. `[["severity", "desc"], ["status", "asc"]]`)
     *
     * @returns a [key, value][] array with the sorting representation
     */
    parseSortString(sortString: string): [keyof SearchOrderByQueryParams, SearchSortOrder][] {
        // Split the input string by commas to get individual fields
        const fields = sortString.split(",").filter(Boolean);

        // Map each field to an object with `field` and `order` properties
        return fields.map((field) => {
            const key = field.startsWith("-") ? field.slice(1) : field;
            return [key as keyof SearchOrderByQueryParams, field.startsWith("-") ? SortOrderEnum.DESCENDING : SortOrderEnum.ASCENDING];
        });
    }

    /**
     * Converts the `orderBy` object to an array of query parameter strings.
     *
     * @param {SearchOrderByQueryParams} orderBy - The object containing properties to sort by,
     * e.g. `{'status': 'desc', 'creation_date': 'asc', ...}`
     * @returns {string} - A comma separated string with the sorting values.
     * e.g. `"-status,creation_date,..."`
     */
    static convertSortToString(sortObject: SearchOrderByQueryParams) {
        const queryParams = Object.entries(sortObject || {})
            .filter(([_, order]) => Boolean(order))
            .map(([property, order]) => {
                return order === SortOrderEnum.ASCENDING ? property : `-${property}`;
            });
        return queryParams.join(",");
    }

    /**
     * Based in the sorting string representation, it handles the final
     * representation of the sorting query param.
     *
     * @param {string} sortingQueryParams - Comes from the url `?sort=`
     * @returns - An object with the filtered sorting values
     * e.g. `{severity: "asc"}`
     */
    _handleSortFromQueryParams(sortingQueryParams: string) {
        if (!sortingQueryParams) return {};

        const incomingSortingElements = this.parseSortString(sortingQueryParams);

        return Object.fromEntries(incomingSortingElements);
    }

    /**
     * Based on the SPA state, it handles the final representation
     * of the sorting query param, it considers the default values.
     *
     * @param {SearchOrderByQueryParams} orderByState - SPA state
     * @returns
     */
    _handleSortFromState(orderByState: SearchOrderByQueryParams) {
        const finalSortingElements = {
            ...this.strategy.SORTING_VALUES,
            ...orderByState,
        };
        return finalSortingElements;
    }

    /**
     * It allows to create new instances when the .getInstance is called.
     *
     * Useful for testing. Before each test the class should be instantiate.
     */
    public static reset() {
        SortingQueryParamsAdapter.adapter = null;
    }
}

export const sortingQueryParamsAdapter = (sortingQueryParams: string | null, orderByState: SearchOrderByQueryParams, strategy?: SortingStrategy) => {
    const adapter = SortingQueryParamsAdapter.getInstance();
    if (strategy) adapter.strategy = strategy;
    return adapter.adapt(sortingQueryParams, orderByState);
};

/**
 * Converts an object of selected filter values into query parameters.
 *
 * @param {SearchSelectedFilterValues} filters - An object where each key is a filter category,
 * and each value is an array of filter items containing an `id` property.
 * @returns {Record<string, string>} An object where each key is a filter category,
 * and each value is a comma-separated string of the selected filter item IDs.
 */
export const filtersToParams = (filters: SearchSelectedFilterValues): Record<string, string> => {
    return Object.fromEntries(
        Object.entries(filters).map(([key, value]) => [key, isArray(value) ? value.map((element) => element.value).join(",") : String(value.value)])
    );
};
