import { ListFilterEnum, TicketStatusCodeEnum } from "appConstants";
import Toggle from "components/toggle/Toggle";
import useURLParams from "hooks/useURLParams";
import moment from "moment";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import styles from "./OverdueFilter.module.scss";
import type { FilterOption, SearchSelectedFilterValues } from "typing/request";

interface IOverdueFilterProps {
    title?: string;
    onUpdate: (selectedList: Record<string, FilterOption>) => void;    
}

export const OverdueFilter = ({ title, onUpdate }: IOverdueFilterProps) => {
    const { setURLParams } = useURLParams();
    const selectedFilterValues: SearchSelectedFilterValues = useSelector(
        (state: RootState) => state.ticketSearch.selectedFilterValues
    );

    const handleOverdue = async (isChecked: boolean) => {
        const completion_date_lte_value = { value: isChecked ? moment().format("YYYY-MM-DD") : "", name: ListFilterEnum.COMPLETION_DATE_LTE }
        const status_lte_value = { value: isChecked ? String(TicketStatusCodeEnum.RESOLVED_COMPLETED) : "", name: ListFilterEnum.STATUS_LTE }

        const isOverdueActive = status_lte_value.value && completion_date_lte_value.value;
        setURLParams({"filter[overdue]": isOverdueActive ? "true" : "false"});
        onUpdate({
            [ListFilterEnum.COMPLETION_DATE_LTE]: completion_date_lte_value,
            [ListFilterEnum.STATUS_LTE]: status_lte_value
        });
    }

    const isOverdueFilterActive = !!(selectedFilterValues[ListFilterEnum.STATUS_LTE] && selectedFilterValues[ListFilterEnum.COMPLETION_DATE_LTE]);

    return (
        <div className={styles.content}>
            {title && <h4 data-testid="text-input-filter-title" className={styles["filter-title"]}>{title}</h4>}
            <Toggle
                isToggled={isOverdueFilterActive}
                onToggle={handleOverdue}
            />
        </div>
    )
}
