import React, { useState } from "react";
import styles from "./TicketEditHistoryFilter.module.scss"
import Multiselect from 'multiselect-react-dropdown';
import { ReactComponent as IconTimes } from "assets/icons/times.svg"
import { ReactComponent as IconArrow } from "assets/icons/arrow.svg"
import classNames from "classnames";
import { HistoryFilterOption } from "store/ticketEditHistorySlice";
import { HistoryFilterOptionEnum } from "appConstants";

interface ITicketEditHistoryHeaderProps {
    onUpdate: (result: HistoryFilterOption[]) => any;
}

export interface IHistoryFilterOption {
    name: HistoryFilterOption,
}

export const TicketEditHistoryHeader: React.FC<ITicketEditHistoryHeaderProps> = ({onUpdate}: ITicketEditHistoryHeaderProps) => {
    const [isFull, setIsFull] = useState(false);
    const options: IHistoryFilterOption[] = [
        {name: HistoryFilterOptionEnum.ACTIVITY},
        {name: HistoryFilterOptionEnum.STAFF_ONLY}, 
        {name: HistoryFilterOptionEnum.STAFF_OWNER}
    ];

    const handleUpdate = (selItems: IHistoryFilterOption[]) => {
        setIsFull(selItems.length === options.length);
        onUpdate(selItems.map(item => item.name));
    };

    return (
        <aside className={classNames(styles.content, 'no-print', isFull ? styles["hide-input"] : null)}>
            <h4>FILTER BY</h4>
            <Multiselect
                placeholder="Add Filters..."
                options={options}
                displayValue="name"
                showArrow={true}
                customArrow={<IconArrow />}
                customCloseIcon={<IconTimes />}
                onSelect={handleUpdate}
                onRemove={handleUpdate}
                hidePlaceholder={isFull}
            />
        </aside>
    );
}
