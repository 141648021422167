import React, {BaseSyntheticEvent, useState} from "react";
import styles from "./TextInputFilter.module.scss";
import { FilterOption } from "typing/request";
import useURLParams from 'hooks/useURLParams';
import classnames from "classnames";
import { TextField } from "@material-ui/core";
import useCallbackWithDebounce from "hooks/useCallbackWithDebounce";

interface TextInputFilterProps {
    disable?: boolean;
    id?: string;
    onUpdate: (selectedList: Record<string, FilterOption>) => void;
    placeholder?: string;
    title?: string;
    defaultText?: string;
    className?: string;
    urlParams?: boolean;
}

export const TextInputFilter: React.FC<TextInputFilterProps> = ({
    disable = false,
    id = 'filter-id',
    onUpdate,
    placeholder = "Start to type...",
    title,
    defaultText = "",
    className,
    urlParams = true,
}: TextInputFilterProps) => {
    const [text, setText] = useState<string>(defaultText);
    const { getURLParam, setURLParams } = useURLParams();

    const onChange = (event: BaseSyntheticEvent): void => {
        const value = event.target.value;
        if (urlParams) setURLParams({[`filter[${id}]`]: value});
        setText(value);
        search(id, value);
    };

    const search = useCallbackWithDebounce((id: string, value: string): void => {
        onUpdate({[id]: {value, name: id}});
    });

    return ( 
        <div className={styles.content}>
            { title && <h4 data-testid="text-input-filter-title" className={styles["filter-title"]}>{title}</h4>}
            <TextField
                id={`${id}_input`}
                type="text"
                className={classnames(styles.datepicker, className)}
                placeholder={placeholder}
                value={getURLParam(`filter[${id}]`) || text}
                onChange={onChange}
                disabled={disable}
                variant="outlined"
            />
        </div>
    );
}
