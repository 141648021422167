import {ListFilterEnum, TicketStatusCodeEnum} from "appConstants";
import moment from "moment";
import type {SearchFilterOptions, SearchSelectedFilterValues} from "typing/request";
import {filtersToParams} from "utils/list-params";

export class FilterQueryParamsAdapter {
    private static adapter: FilterQueryParamsAdapter | null;

    /**
     * Get Singleton Instance
     */
    public static getInstance(): FilterQueryParamsAdapter {
        if (!FilterQueryParamsAdapter.adapter) FilterQueryParamsAdapter.adapter = new FilterQueryParamsAdapter();
        return FilterQueryParamsAdapter.adapter;
    }

    /**
     * Adapts the filter query parameters by applying all relevant handlers.
     *
     * @param filterQueryParams - The original filter query parameters to adapt, comes from URL.
     * @param selectedFilterValues - State of the app that stores the query params as FilterOption.
     * @returns The adapted filter query parameters.
     */
    adapt(filterQueryParams: SearchFilterOptions, selectedFilterValues: SearchSelectedFilterValues) {
        const result = this.handleOverdue(filterQueryParams);
        return Object.keys(filterQueryParams).length ? result : filtersToParams(selectedFilterValues);
    }

    /**
     * Handles the `overdue` filter by modifying the query parameters accordingly.
     *
     * If the `OVERDUE` parameter is set to `true`, this method:
     * - Sets `STATUS_LTE` to the ID corresponding to `RESOLVED_COMPLETED` status.
     * - Sets `COMPLETION_DATE_LTE` to the current date in "YYYY-MM-DD" format.
     * - Removes the `OVERDUE` parameter from the query parameters.
     */
    handleOverdue(filterQueryParams: SearchFilterOptions) {
        if (filterQueryParams[ListFilterEnum.OVERDUE] === "true") {
            filterQueryParams[ListFilterEnum.STATUS_LTE] = String(TicketStatusCodeEnum.RESOLVED_COMPLETED - 1);
            filterQueryParams[ListFilterEnum.COMPLETION_DATE_LTE] = moment().format("YYYY-MM-DD");
        }
        delete filterQueryParams[ListFilterEnum.OVERDUE];

        return filterQueryParams;
    }

    // Add more handler methods as needed
}

/**
 * Adapts the filterQueryParams that will send to the ticketing API.
 *
 * e.g. `?overdue=true` is equal to `status_lte=9&completion_date_lte=todayDate` in Ticketing API
 *
 * @param filterQueryParams - The original filter query parameters to adapt.
 * @param selectedFilterValues - The selected filter values.
 * @returns The adapted filter query parameters.
 */
export const filterQueryParamsAdapter = (filterQueryParams: SearchFilterOptions, selectedFilterValues: SearchSelectedFilterValues) => {
    const adapter = FilterQueryParamsAdapter.getInstance();
    return adapter.adapt(filterQueryParams, selectedFilterValues);
};
