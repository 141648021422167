import React, {useEffect, useState} from "react";
import styles from "./DatePickerFilter.module.scss";
import { FilterOption } from "typing/request";
import useURLParams from 'hooks/useURLParams';
import { DatePicker } from "@vacasa/react-components-lib";
import classnames from "classnames";
import moment from "moment";
import { enqueueError } from "utils/message";
import { EVENT_LOG_DATA } from "appConstants";

interface DatePickerFilterProps {
    disable?: boolean;
    id?: string;
    onUpdate: (selectedList: Record<string, FilterOption>) => void;
    placeholder?: string;
    title?: string;
    date: Date | null
    className?: string;
    urlParams?: boolean;
    dateFormat?: string;
    minDate?: Date | null;
    maxDate?: Date | null;
    setDate?: (date: Date | null) => void
}

export const DatePickerFilter: React.FC<DatePickerFilterProps> = ({
    disable,
    id = 'filter-id',
    onUpdate,
    placeholder = 'Pick a date',
    title,
    className,
    urlParams = true,
    dateFormat = "MM/dd/yyyy",
    minDate,
    maxDate,
    date,
    setDate,
}: DatePickerFilterProps) => {
    const [datePickerOpen, setDatePickerOpen] = useState(false);
    const { setURLParams } = useURLParams();
    const paramDateFormat = "YYYY-MM-DD";
    const [validDate, setValidDate] = useState(date);
    const [errorMessage, setErrorMessage] = useState("");
    const [forceRender, setForceRender] = useState(0);

    const isValid = (date: Date|null): boolean => {
        setErrorMessage("");
        if (!date || !moment(date).isValid()) {
            setErrorMessage("The date is invalid");
            return false;
        }
        if (minDate && moment(date).isBefore(minDate)) {
            setErrorMessage(`The date must be after: ${moment(minDate).format(paramDateFormat)}`);
            return false;
        }
        if (maxDate && moment(date).isAfter(maxDate)) {
            setErrorMessage(`The date must be before: ${moment(maxDate).format(paramDateFormat)}`);
            return false;
        }
        return true;
    }

    const handleBlur = () => {
        if (!isValid(date)) {
            enqueueError({
                logInfo: EVENT_LOG_DATA.FILTER_DATE_ADD,
                error: new Error(errorMessage)
            });
            saveDate(validDate);
            return;
        }
        forceRerender();
    };

    const forceRerender = () => setForceRender(prev => !prev ? 1 : 0);

    const saveDate = (date: Date | null) => {
        if (setDate) setDate(date);
    };

    const updateDate = (newDate: Date): void => {
        saveDate(newDate);
        if (!isValid(newDate)) return;
        const dateParam = moment(newDate).local(false).format(paramDateFormat);
        if (urlParams) setURLParams({[`filter[${id}]`]: dateParam});
        onUpdate({[id]: {value: dateParam, name: id}});
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") handleBlur();
    };

    useEffect(() => {
        if (isValid(date)) setValidDate(date);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date]);

    return ( 
        <div className={styles.content}>
            { title && <h4 data-testid="date-picker-filter-title" className={styles["filter-title"]}>{title}</h4>}
            <DatePicker
                key={forceRender}
                autoOk={true}
                className={classnames(styles.datepicker, className)}
                open={datePickerOpen}
                placeholder={disable ? "Disabled" : placeholder}
                value={date}
                onChange={updateDate}
                onOpen={() => setDatePickerOpen(true)}
                onClose={() => setDatePickerOpen(false)}
                format={dateFormat}
                variant="inline"
                disableToolbar={true}
                disabled={disable}
                minDate={minDate}
                maxDate={maxDate}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
            />
        </div>
    );
}
