import { ListFilterEnum } from "appConstants";
import Toggle from "components/toggle/Toggle";
import useURLParams from "hooks/useURLParams";
import moment from "moment";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import styles from "./StaysInProgressFilter.module.scss";
import type { FilterOption, SearchSelectedFilterValues } from "typing/request";

interface IStayInProgressFilterProps {
    title?: string;
    onUpdate: (selectedList: Record<string, FilterOption>) => void;
}

export const StaysInProgressFilter = ({ title, onUpdate }: IStayInProgressFilterProps) => {
    const { setURLParams } = useURLParams();
    const selectedFilterValues: SearchSelectedFilterValues = useSelector(
        (state: RootState) => state.ticketSearch.selectedFilterValues
    );

    const handleStayInProgress = async (isChecked: boolean) => {
        const reservationStartDateValue = { value: isChecked ? moment().subtract(2, "weeks").format("YYYY-MM-DD") : "", name: ListFilterEnum.RESERVATION_DATE_GTE }
        const reservationEndDateValue = { value: isChecked ? moment().format("YYYY-MM-DD") : "", name: ListFilterEnum.RESERVATION_DATE_LTE }
        setURLParams({
            "filter[reservation_date_gte]": reservationStartDateValue?.value,
            "filter[reservation_date_lte]": reservationEndDateValue?.value
        });
        onUpdate({
            [ListFilterEnum.RESERVATION_DATE_GTE]: reservationStartDateValue,
            [ListFilterEnum.RESERVATION_DATE_LTE]: reservationEndDateValue
        });
    }

    const isStayInProgressFilterActive = !!(selectedFilterValues[ListFilterEnum.RESERVATION_DATE_GTE]?.value && selectedFilterValues[ListFilterEnum.RESERVATION_DATE_LTE]?.value);

    return (
        <div className={styles.content}>
            {title && <h4 data-testid="stays-in-progress-filter-title" className={styles["filter-title"]}>{title}</h4>}
            <Toggle
                id={"stays_in_progress_toggle"}
                isToggled={isStayInProgressFilterActive}
                onToggle={handleStayInProgress}
            />
        </div>
    )
}
