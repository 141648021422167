import React, { useEffect, useState } from "react";
import styles from "./TicketSearchFilters.module.scss";
import { MultiSelectFilter } from "components/multi-select-filter/MultiSelectFilter";
import { RootState, useAppDispatch } from "store/store";
import {openErrorAlert, setPage, setReload, setSelectedFilterValues} from "store/ticketSearchSlice";
import { TicketingService } from "services/TicketingService";
import { UnitService } from "services/UnitService";
import { useSelector } from "react-redux";
import { CLOSE_STATUS, ListFilterEnum, OPEN_STATUS, DEFAULT_SEARCH_PAGINATION_VALUES } from "appConstants";
import { getDefaultUsers, getSelectedDates, getSelectedOverdue, getSelectedStatus, getSelectedUnits, getSelectedUsers, transformUnitToFilterItem, getSelectedSeverity, getSelectedVisibility } from "./TicketSearchFilter.helpers";
import { TextInputFilter } from "components/text-input-filter/TextInputFilter";
import { DatePickerFilter } from "components/date-picker-filter/DatePickerFilter";
import moment from "moment";
import { OverdueFilter } from "components/ticket-search-overdue-filter/OverdueFilter";
import { StaysInProgressFilter } from "components/ticket-edit-stays-in-progress-filter/StaysInProgressFilter";
import type { DataType, FilterOption, SearchFilterOptions, SearchSelectedFilterValues } from "typing/request";
import type { AssignableUserAttributes, UnitV2Attributes, UserConnectAttributes } from "typing/dto";
import { ConnectService } from "services/ConnectService";
import { TicketEntity } from "entities/ticket";
import useURLParams from "hooks/useURLParams";
import { resetURLPagination } from "utils/url-params";
import Tooltip from "@mui/material/Tooltip";
import classNames from "classnames";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";

interface ITicketSearchFiltersProps {
    filterQueryParams?: SearchFilterOptions,
    units?: UnitV2Attributes[],
    users?: UserConnectAttributes[],
}

enum SEARCH_BUTTON_TEXT {
    SUBMIT = 'Submit',
    LOADING = 'Loading...'
}

export const TicketSearchFilters: React.FC<ITicketSearchFiltersProps> = ({ filterQueryParams = {}, units = [], users = [] }: ITicketSearchFiltersProps) => {
    const dispatch = useAppDispatch();
    const { setURLParams } = useURLParams();
    const [managerOptions, setManagerOptions] = useState([] as FilterOption[]);
    const [assigneeOptions, setAssigneeOptions] = useState([] as FilterOption[]);
    const [followerOptions, setFollowerOptions] = useState([] as FilterOption[]);
    const [creatorOptions, setCreatorOptions] = useState([] as FilterOption[]);
    const [unitOptions, setUnitOptions] = useState([] as FilterOption[]);
    const [searchFilters, setSearchFilters] = useState<SearchSelectedFilterValues>({});
    const [isSearchModified, setIsSearchModified] = useState<boolean>(false);
    const isLoading = useSelector((state: RootState) => state.ticketSearch.isLoading);

    // Selected filters
    const selectedFilterValues: SearchSelectedFilterValues = useSelector(
        (state: RootState) => state.ticketSearch.selectedFilterValues
    );

    // Datepickers
    const selectedStartDate =  useSelector((state: RootState) => state.ticketSearch.selectedFilterValues.creation_date_gte);
    const selectedEndDate =  useSelector((state: RootState) => state.ticketSearch.selectedFilterValues.creation_date_lte);
    const [startDate, setStartDate] = useState<Date|null>(new Date());
    const [endDate, setEndDate] = useState<Date|null>(new Date());

    // Multiselect
    const selectedFilterStatus =  useSelector((state: RootState) => state.ticketSearch.selectedFilterValues.status);
    const [selectedStatus, setSelectedStatus] = useState(selectedFilterStatus || []);

    const ticketingService = TicketingService.getInstance();
    const unitService = UnitService.getInstance();
    const connectService = ConnectService.getInstance();

    useEffect(() => {
        /** 
         * Initializes filter values from query parameters. 
         * 
         * IMPORTANT: It does NOT has deps.
        */
       const initializeFilterValues = () => {
            const selectedStatus = getSelectedStatus(filterQueryParams, TicketEntity.statusOptions);
            const selectedDates = getSelectedDates(filterQueryParams);
            const selectedOverdue = getSelectedOverdue(filterQueryParams);
            const defaultUsers = getDefaultUsers(filterQueryParams, connectService.currentUser);
            const selectedSeverity = getSelectedSeverity(filterQueryParams, TicketEntity.severityOptions);
            const selectedVisibility = getSelectedVisibility(filterQueryParams, TicketEntity.visibilityOptions);

            dispatch(setSelectedFilterValues({
                ...selectedFilterValues,
                ...defaultUsers,
                ...selectedStatus,
                ...selectedDates,
                ...selectedOverdue,
                ...selectedSeverity,
                ...selectedVisibility,
            }));
        }
        initializeFilterValues();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        /**
         * Initializes filter values from query parameters.
         * 
         * This function retrieves the selected users and units from query parameters
         * then dispatches an action to update the selected filter values.
         * 
         * IMPORTANT: It has deps.
        */
        if (!users.length && !units.length) return;
        const initializeFilterValues = () => {
            const selectedUsers = getSelectedUsers(filterQueryParams, users);
            const selectedUnits = getSelectedUnits(filterQueryParams, units);
            dispatch(setSelectedFilterValues({
                ...selectedFilterValues,
                ...selectedUsers,
                ...selectedUnits,
            }));
        }
        initializeFilterValues();
    }, [users, units]) // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Synchronizes the local state the selected values
     * from the global state whenever it changes.
     */
    useEffect(() => { if(selectedStartDate) setStartDate(moment(selectedStartDate.value).toDate()); }, [selectedStartDate]);
    useEffect(() => { if(selectedEndDate) setEndDate(moment(selectedEndDate.value).toDate()); }, [selectedEndDate]);
    useEffect(() => { if(selectedFilterStatus) setSelectedStatus(selectedFilterStatus); }, [selectedFilterStatus]);

    const updateList = (selectedList: Record<string, FilterOption | FilterOption[]>) => {
        const newFilters = {
            ...selectedFilterValues,
            ...searchFilters,
            ...selectedList,
        };
        setSearchFilters(newFilters);
        setIsSearchModified(!isEqual(clearFilterOption(newFilters), clearFilterOption(selectedFilterValues)));
    };

    const clearFilterOption = (option: Record<string, FilterOption | FilterOption[]>) => {
        const filteredEntries = Object
            .entries(option)
            .filter(([_, value]) => Boolean((value as FilterOption)?.value || (Array.isArray(value) && !isEmpty(value))));
        return Object.fromEntries(filteredEntries);
    };

    const errorHandler = (fetcherContext: string) => {
        dispatch(openErrorAlert(
            `Error on fetching ${fetcherContext.toUpperCase()}, please get in touch with your support team or try again.`
        ));
    }

    const fetchUnits = async (searchString: string): Promise<FilterOption[] | void> => {
        try {
            const units = await unitService.getUnitsByParams({
                filter: {
                    unit_code: {
                        startswith: searchString.toUpperCase()
                    }
                }
            }, {requestCancellation: true});
            return transformUnitToFilterItem(units)
        } catch (e) {
            errorHandler('units');
            return Promise.reject(`Error: ${JSON.stringify(e)}`);
        }
    };

    const fetchAssignees = async (searchString: string, errorContext: string): Promise<FilterOption[] | void> => {
        try {
            const assignees = await ticketingService.getAssignees({
                search_term: searchString
            }, {}, {requestCancellation: true});
            return assignees.data.map((user: DataType<AssignableUserAttributes>) => {
                const { attributes, id } = user;
                const { first_name, last_name } = attributes;
                return {
                    name: `${first_name} ${last_name}`,
                    value: id
                }
            });
        } catch (e) {
            errorHandler(errorContext);
            return Promise.reject(`Error: ${JSON.stringify(e)}`);
        }
    };

    const fetchCreatedBy = async (searchString: string, errorContext: string): Promise<FilterOption[] | void> => {
        try {
            const data = await connectService.getUsers({name: searchString}, {}, {requestCancellation: true});
            return data.map((user: any) => {
                const { attributes, id } = user;
                const { first_name, last_name } = attributes;
                return {
                    name: `${first_name} ${last_name}`,
                    value: id
                }
            });
        } catch (e) {
            errorHandler(errorContext);
            return Promise.reject(`Error: ${JSON.stringify(e)}`);
        }
    };

    // STATUS
    const defaultStatus = [
        OPEN_STATUS,
        CLOSE_STATUS,
        ...TicketEntity.statusOptions
    ] as FilterOption[];
    const [statusOptions, setStatusOptions] = useState(defaultStatus);

    const search = () => {
        resetURLPagination(setURLParams);
        dispatch(setPage({
            limit: DEFAULT_SEARCH_PAGINATION_VALUES.LIMIT,
            number: DEFAULT_SEARCH_PAGINATION_VALUES.NUMBER
        }));
        dispatch(setSelectedFilterValues(searchFilters))
        dispatch(setReload());
        setIsSearchModified(false);
    }

    const isSearchDisabled = !isSearchModified || isLoading;
    const isSearchEnabled = !isLoading && !isSearchModified

    return (
        <div data-testid="ticket-search-filters" >
            <div className={styles.content}>
                <div className={styles.item}>
                    <MultiSelectFilter
                        title="Manager"
                        id={ListFilterEnum.MANAGER_ID}
                        options={managerOptions}
                        onFetchData={(searchString: string) => fetchAssignees(searchString, "managers")}
                        onUpdate={updateList}
                        setOptions={setManagerOptions}
                        selectedValues={selectedFilterValues[ListFilterEnum.MANAGER_ID]}
                    />
                </div>
                <div className={styles.item}>
                    <MultiSelectFilter
                        title="Assignee"
                        id={ListFilterEnum.ASSIGNED_TO}
                        options={assigneeOptions}
                        onFetchData={(searchString: string) => fetchAssignees(searchString, "assignees")}
                        onUpdate={updateList}
                        setOptions={setAssigneeOptions}
                        selectedValues={selectedFilterValues[ListFilterEnum.ASSIGNED_TO]}
                    />
                </div>
                <div className={styles.item}>
                    <MultiSelectFilter
                        title="Follower"
                        id={ListFilterEnum.FOLLOWER_ID}
                        options={followerOptions}
                        onFetchData={(searchString: string) => fetchAssignees(searchString, "followers")}
                        onUpdate={updateList}
                        setOptions={setFollowerOptions}
                        selectedValues={selectedFilterValues[ListFilterEnum.FOLLOWER_ID]}
                    />
                </div>
                <div className={styles.item}>
                    <MultiSelectFilter
                        title="Unit Code"
                        id={ListFilterEnum.UNIT_ID}
                        options={unitOptions}
                        onFetchData={fetchUnits}
                        onUpdate={updateList}
                        setOptions={setUnitOptions}
                        selectedValues={selectedFilterValues[ListFilterEnum.UNIT_ID]}
                    />
                </div>
            </div>
            <div className={styles.content}>
                <div className={styles.item}>
                    <DatePickerFilter
                        id={ListFilterEnum.START_DATE}
                        title="Start Date"
                        onUpdate={updateList}
                        className={styles.datepicker}
                        date={startDate}
                        maxDate={endDate}
                        setDate={setStartDate}
                    />
                </div>
                <div className={styles.item}>
                    <DatePickerFilter
                        id={ListFilterEnum.END_DATE}
                        title="End Date"
                        date={endDate}
                        onUpdate={updateList}
                        className={styles.datepicker}
                        minDate={startDate}
                        setDate={setEndDate}
                    />
                </div>
                <div className={styles.item}>
                    <MultiSelectFilter
                        title="Status"
                        id={ListFilterEnum.STATUS}
                        options={statusOptions}
                        onUpdate={updateList}
                        selectedValues={selectedStatus}
                        setSelectedValues={setSelectedStatus}
                        minimumCharacters={0}
                        isGroupingEnabled={true}
                        defaultOptions={defaultStatus}
                        setOptions={setStatusOptions}
                    />
                </div>
                <div className={styles.item}>
                    <TextInputFilter
                        title="Ticket Title"
                        id={ListFilterEnum.TITLE}
                        onUpdate={updateList}
                    />
                </div>
            </div>
            <div className={styles.content}>
                <div className={styles.item}>
                    <MultiSelectFilter
                        title="Severity"
                        id={ListFilterEnum.SEVERITY}
                        options={TicketEntity.severityOptions}
                        onUpdate={updateList}
                        selectedValues={selectedFilterValues[ListFilterEnum.SEVERITY]}
                        minimumCharacters={0}
                    />
                </div>
                <div className={styles.item}>
                    <MultiSelectFilter
                        title="Created By"
                        id={ListFilterEnum.CREATED_BY}
                        options={creatorOptions}
                        onFetchData={(searchString: string) => fetchCreatedBy(searchString, "creators")}
                        onUpdate={updateList}
                        setOptions={setCreatorOptions}
                        selectedValues={selectedFilterValues[ListFilterEnum.CREATED_BY]}
                    />
                </div>
                <div className={styles.item}>
                    <MultiSelectFilter
                        title="Visibility"
                        id={ListFilterEnum.VISIBILITY}
                        options={TicketEntity.visibilityOptions}
                        onUpdate={updateList}
                        selectedValues={selectedFilterValues[ListFilterEnum.VISIBILITY]}
                        minimumCharacters={0}
                    />
                </div>
                <div className={styles.item}>
                    <OverdueFilter
                        title="Overdue"
                        onUpdate={updateList}
                    />
                </div>
            </div>
            <div className={styles.content}>
                <div className={styles.item}>
                    <TextInputFilter
                        title="by Job Title"
                        id={ListFilterEnum.JOB_TITLE}
                        onUpdate={updateList}
                    />
                </div>
                <div className={styles.item}>
                    <StaysInProgressFilter
                        title="Stays In Progress"
                        onUpdate={updateList}
                    />
                </div>
                <div className={styles.item}></div>
                <div className={styles.item}></div>
            </div>
            <div className={classNames(styles.content, 'justify-content-end')}>
                <Tooltip title={isSearchDisabled ? "Update filters to search" : ""} arrow disableInteractive>
                    <>
                        <button id={"ticket-search-btn"} disabled={isSearchDisabled} onClick={search} className={classNames(
                            styles['button'],
                            'button',
                            'button-primary-inverse',
                            {"active": !isSearchDisabled},
                            styles["accept-button"],
                            'no-print'
                        )}>
                            {isLoading && SEARCH_BUTTON_TEXT.LOADING}
                            {(isSearchModified || isSearchEnabled) && SEARCH_BUTTON_TEXT.SUBMIT}
                        </button>
                    </>
                </Tooltip>
            </div>
        </div>
    );
}

