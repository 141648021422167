import React from "react";
import { Alert, AlertColor } from "@mui/material";
import classNames from "classnames";

export interface AlertMessageParams {
    text?: string;
    severity?: AlertColor;
    visible?: boolean;
    variant?: "outlined" | "standard" | "filled";
}

interface AlertMessageProps {
    params: AlertMessageParams,
    onClose?: () => any;
    className?: string;
}

export const AlertMessage: React.FC<AlertMessageProps> = ({params, onClose, className}: AlertMessageProps) => {
    let {text, severity = 'error', visible = true, variant = "outlined"} = params;
    visible = !text ? false : visible;
    return (
        <div>
        { visible && 
            <Alert className={classNames(className)} data-testid="alert-message" variant={variant} severity={severity} onClose={onClose}>
                {text}
            </Alert>
        }
        </div>
    );
}
