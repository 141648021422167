import React, { useEffect, useState } from "react";
import styles from "./TicketEditHeader.module.scss";
import { HeaderTitle } from "components/header-title/HeaderTitle";
import { TicketSalesforceParentV1Attributes, TicketV1Attributes } from "typing/dto";
import { LoaderButton } from "components/loader-button/LoaderButton";
import { ConfirmDialog } from "components/confirm-dialog/ConfirmDialog";
import { useDispatch, useSelector } from "react-redux";
import { patchTicketHeader, setHeaderTicket } from "store/ticketEditHeaderSlice";
import { RootState } from "store/store";
import { TicketStatus, fetchTicket, silentLoading } from "store/ticketEditSlice";
import { TicketEditHeaderSnoozeModal } from "components/ticket-edit-header-snooze-modal/TicketEditHeaderSnoozeModal";
import { InlineEdit } from "components/inline-edit/InlineEdit";
import { TicketEditHeaderVisibilityEdit } from "components/ticket-edit-header-visibility-edit/TicketEditHeaderVisibilityEdit";
import { ThunkDispatch } from "redux-thunk";
import { enqueueError, enqueueSuccess, getErrorMessage } from "utils/message";
import { EVENT_LOG_DATA, HeaderMessageEnum, TicketStatusCodeEnum, TicketStatusEnum, VisibilityStatusEnum } from "appConstants";
import { EventLogInfo } from "typing/message";
import moment from "moment";
import { env } from "core/env";
import { fetchHistory } from "store/ticketEditHistorySlice";
import { AlertMessage } from "components/alert-message/AlertMessage";
import { ReactComponent as IconLink } from "../../assets/icons/link.svg";
import { CopyToClipboard } from "components/copy-to-clipboard/CopyToClipboard";
import { DispositionEntity } from "entities/Disposition";

interface ITicketEditHeaderProps {
    ticket: TicketV1Attributes
}

export const TicketEditHeader: React.FC<ITicketEditHeaderProps> = ({ticket}) => {
    const {
        TICKET_HEADER_AWAKE,
        TICKET_HEADER_CANCEL,
        TICKET_HEADER_COMPLETE,
        TICKET_HEADER_REOPEN,
        TICKET_HEADER_SNOOZE,
        TICKET_HEADER_START,
        TICKET_HEADER_UNPAIR,
        TICKET_TITLE_UPDATE,
        TICKET_VISIBILITY_UPDATE, 
    } = EVENT_LOG_DATA;
    const ticketId = Number(ticket.id) || 0;
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const statusMap = new Map<TicketStatus, string>([
        [TicketStatusEnum.OPEN, "Not started"],
        [TicketStatusEnum.STARTED, "Started"],
        [TicketStatusEnum.CANCELED, "Canceled"],
        [TicketStatusEnum.COMPLETED, "Completed"]
    ]);

    const visibility: VisibilityStatusEnum = useSelector((state: RootState) => (state.ticketEdit?.ticket?.visibility || VisibilityStatusEnum.INTERNAL));
    const title: string = useSelector((state: RootState) => (state.ticketEdit?.ticket?.title || ""));
    const isCurrentUserAssigned: boolean = useSelector((state: RootState) => state.ticketEdit.isCurrentUserAssigned);

    const isStarted: boolean = useSelector((state: RootState) => state.ticketEdit.isStarted);
    const isCompleted: boolean = useSelector((state: RootState) => state.ticketEdit.isCompleted);
    const isCanceled: boolean = useSelector((state: RootState) => state.ticketEdit.isCanceled);
    const ticketStatus: TicketStatus = useSelector((state: RootState) => state.ticketEdit.ticketStatus);

    const isSnoozed: boolean = useSelector((state: RootState) => state.ticketEditHeader.isSnoozed);
    const showUnpair: boolean = useSelector((state: RootState) => state.ticketEditHeader.showUnpair);
    const salesforceParent: TicketSalesforceParentV1Attributes[] = useSelector((state: RootState) => state.ticketEditHeader.salesforceParent);

    const dispositionFirstLevel = useSelector((state: RootState) => state.ticketEditInfo.dispositionFirstLevel);
    const dispositionSecondLevel = useSelector((state: RootState) => state.ticketEditInfo.dispositionSecondLevel);
    const dispositionThirdLevel = useSelector((state: RootState) => state.ticketEditInfo.dispositionThirdLevel);
    const dispositionEntity = new DispositionEntity(
        dispositionFirstLevel,
        dispositionSecondLevel,
        dispositionThirdLevel
    );
    const isOwnerRefundRequest = dispositionEntity.isOwnerRefundRequest();

    const showComplete: boolean = !isCompleted && isStarted && !isCanceled;
    const showAccept: boolean = !isCompleted && !isStarted && !isCanceled;
    const showCancel: boolean = !isCompleted && !isCanceled;
    const showReopen: boolean = isCompleted || isCanceled;
    const showSnooze: boolean = !isSnoozed && isCurrentUserAssigned;
    const showWake: boolean = isSnoozed && isCurrentUserAssigned;
    
    const [showCancelConfirm, setShowCancelConfirm] = useState(false);
    const [showReopenConfirm, setShowReopenConfirm] = useState(false);
    const [showSnoozeModal, setShowSnoozeModal] = useState(false);
    const statusLabel = statusMap.get(ticketStatus) || "Not started";
    const isVisibleToOwner = visibility === VisibilityStatusEnum.OWNER;

    const getNow = () => moment().toISOString();

    useEffect(() => {
        dispatch(setHeaderTicket(ticket));
    }, [ticket, dispatch]);
    
    const silentReload = async() => {
        await dispatch(silentLoading());
        await dispatch(fetchTicket(Number(ticket.id)));
    };

    const updateSnooze = async(date: Date | null) => {
        await patchRequest(
            {snooze: (date ? date.toISOString() : null)}, 
            date ? TICKET_HEADER_SNOOZE : TICKET_HEADER_AWAKE
        );
    }

    const begin = async() => {
        await patchRequest({
            start_date: getNow(),
            status: TicketStatusCodeEnum.ACCEPTED
        }, TICKET_HEADER_START);
    };

    const complete = async() => {
        await patchRequest({
            completed_date: getNow(),
            status: TicketStatusCodeEnum.RESOLVED_COMPLETED,
        }, TICKET_HEADER_COMPLETE);
    };

    const unpair = async() => {
        await patchRequest({sf_case_id: null}, TICKET_HEADER_UNPAIR);
    };

    const cancel = async() => {
        await patchRequest({
            void: getNow(),
            status: TicketStatusCodeEnum.RESOLVED_DISMISSED,
        }, TICKET_HEADER_CANCEL);
        await closeCancelDialog();
    };

    const updateTitle = async(title: string) => {
        await patchRequest({title}, TICKET_TITLE_UPDATE);
    }

    const updateVisibility = async(visibility: VisibilityStatusEnum) => {
        await patchRequest({visibility}, TICKET_VISIBILITY_UPDATE);
    };
    
    const reopen = async() => {
        await patchRequest(
            {
                status: TicketStatusCodeEnum.ASSIGNED,
                last_reopen: getNow(),
            },
            TICKET_HEADER_REOPEN
        );
        await closeReopenDialog();
    };

    const patchRequest = async(
        attributes: Partial<TicketV1Attributes>, 
        logInfo : EventLogInfo,
    ) => {
        try {
            await dispatch(
                patchTicketHeader({
                    id: Number(ticket.id),
                    attributes,
                })
            ).unwrap();
            await silentReload();
            enqueueSuccess({logInfo, data: attributes});
            dispatch(fetchHistory(ticketId));
        } catch(error) {
            enqueueError({logInfo, error: Error(getErrorMessage(error))});
        }
    };

    const openCancelDialog = async() => setShowCancelConfirm(true);
    const closeCancelDialog = async() => setShowCancelConfirm(false);

    const openReopenDialog = async() => setShowReopenConfirm(true);
    const closeReopenDialog = async() => setShowReopenConfirm(false);

    const openSnoozeModal = async() => setShowSnoozeModal(true);
    const closeSnoozeModal = async() => setShowSnoozeModal(false);

    const headerTitleLink = `${env.REACT_APP_ADMIN_URL}/admin/dashboard/ticket/${ticketId}`;
    const headerTitleClipboard: ClipboardItems = [new ClipboardItem({
        "text/html": new Blob([`Ticket ID: <a href='${headerTitleLink}'>${ticketId}</a>`], { type: "text/html" }),
        "text/plain": new Blob([headerTitleLink], { type: "text/plain" })
    })];
    const headerTitle = (
        <>
            Ticket #
            <a href={headerTitleLink} onClick={(e: React.MouseEvent<HTMLAnchorElement>) => { if (e.button === 0) e.preventDefault() }}>
                <CopyToClipboard content={headerTitleClipboard}>
                    {ticketId} <IconLink width={18} height={18} />
                </CopyToClipboard>
            </a>
        </>);

    return ( 
        <div data-testid="ticket-edit-header" className={styles.content}>
            {/* Alert message */}
            {isVisibleToOwner && <AlertMessage className={styles["alert-message"]} params={{text: "This ticket is visible to the unit owner(s).", severity: 'info', variant: 'outlined'}}/>}

            {/* Title & Unpair  */}
            <HeaderTitle.Container>
                <HeaderTitle.Left title={headerTitle}>
                    {/* Visibility component */}
                    <TicketEditHeaderVisibilityEdit visibility={visibility} onAccept={updateVisibility}/>
                </HeaderTitle.Left>
                <HeaderTitle.Right>
                    {showUnpair && <LoaderButton id="ticket-header-unpair-btn" onClick={unpair}>Unpair From Case</LoaderButton>}
                </HeaderTitle.Right>
            </HeaderTitle.Container>
            
            {/* Brief & Actions */}
            <HeaderTitle.Container>
                <HeaderTitle.Left className={styles["left-container"]}>
                    <aside>
                        {/* Title component */}
                        <InlineEdit onAccept={updateTitle} title="Title" isHTMLAllowed={false}>{title}</InlineEdit>
                    </aside>
                    <aside className={styles["button-container"]}>
                        {showWake && <LoaderButton id="ticket-header-wake-btn" size="small" onClick={() => updateSnooze(null)}>Wake</LoaderButton>}
                        {showSnooze && <LoaderButton id="ticket-header-snooze-btn" size="small" onClick={openSnoozeModal}>Snooze</LoaderButton>}
                        {showCancel && <LoaderButton id="ticket-header-cancel-btn" size="small" variant="secondary" onClick={openCancelDialog}>Cancel</LoaderButton>}
                        {showReopen && <LoaderButton id="ticket-header-reopen-btn" size="small" variant="secondary" onClick={openReopenDialog}>Reopen</LoaderButton>}
                    </aside>
                </HeaderTitle.Left>
                <HeaderTitle.Right>
                    <aside className={styles["right-container"]}>
                        {salesforceParent?.length ?
                            <>
                                <h3 data-testid="salesforce-parent-case-id">
                                    <strong>Parent Case #</strong>: {salesforceParent[0].case_number}
                                </h3>
                                <h3 data-testid="salesforce-parent-case-subject">
                                    <strong>Parent Case Title</strong>: {salesforceParent[0].subject}
                                </h3>
                                <h3 data-testid="salesforce-parent-case-status">
                                    <strong>Parent Case Status</strong>: {salesforceParent[0].status}
                                </h3>
                            </> : null
                        }
                        <h2>Status: {statusLabel}</h2>
                    </aside>
                    <aside className={styles["button-container"]}>
                        {showComplete && <LoaderButton id="ticket-header-complete-btn" variant="primary-inverse" onClick={complete} className={styles["accept-button"]}>Complete</LoaderButton>}
                        {showAccept && <LoaderButton id="ticket-header-accept-btn" variant="primary-inverse" onClick={begin} className={styles["accept-button"]}>Accept</LoaderButton>}
                    </aside>
                </HeaderTitle.Right>
            </HeaderTitle.Container>
            {/* Cancel confirm dialog */}
            <ConfirmDialog
                data-testid="ticket-header-confirm-dialog-cancel"
                title="Cancel"
                text={HeaderMessageEnum.CANCEL_TICKET} 
                visible={showCancelConfirm} 
                onAccept={cancel} 
                onClose={closeCancelDialog} 
            />
            {/* Reopen confirm dialog */}
            <ConfirmDialog
                data-testid="ticket-header-confirm-dialog-reopen"
                title="Reopen"
                text={isOwnerRefundRequest ? HeaderMessageEnum.OWNER_REQUEST_REOPEN : HeaderMessageEnum.REOPEN_TICKET}
                visible={showReopenConfirm} 
                onAccept={isOwnerRefundRequest ? null : reopen} 
                onClose={closeReopenDialog} 
                cancelLabel={isOwnerRefundRequest ? "Close" : undefined}
            />
            {/* Snooze modal */}
            <TicketEditHeaderSnoozeModal showModal={showSnoozeModal} onClose={closeSnoozeModal} onAccept={updateSnooze} />
        </div>
    );
}

