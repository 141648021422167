import {RootState} from "./store";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {DispositionsEnum, EVENT_LOG_DATA} from "appConstants";
import {TicketEntity} from "entities/ticket";
import intersection from "lodash/intersection";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import isNumber from "lodash/isNumber";
import moment from "moment";
import {ReservationService} from "services/ReservationService";
import {TicketingService} from "services/TicketingService";
import {DispositionV1Attributes, TicketV1Attributes} from "typing/dto";
import {DataType} from "typing/request";
import {enqueueError, enqueueSuccess, getErrorMessage} from "utils/message";

const {TICKET_INFO_UPDATE} = EVENT_LOG_DATA;

// Service Singletons
const ticketingService = TicketingService.getInstance();
const reservationService = ReservationService.getInstance();

interface fetchInfoPayload {
    dispositions: Array<DataType<DispositionV1Attributes>>;
    selectedTierOneOption: DataType<DispositionV1Attributes>;
    selectedTierTwoOption: DataType<DispositionV1Attributes>;
    selectedTierThreeOption: DataType<DispositionV1Attributes>;
    dispositionTierOneOptions: Array<DataType<DispositionV1Attributes>>;
    dispositionTierTwoOptions: Array<DataType<DispositionV1Attributes>>;
    dispositionTierThreeOptions: Array<DataType<DispositionV1Attributes>>;
}

/**
 * Checks if Reopen Based On should display Custom Option
 */
function isCustomReopenBasedOn(reopenBasedOn: string) {
    return reopenBasedOn === "frequency_start_date_cycle";
}

/**
 * Checks if Due Date should display in Custom Option
 */
function isCustomDueDate(recurringBeforeDueDate: number | null) {
    return !Object.keys(TicketEntity.frequencyReopenAfterDuedate).includes(String(recurringBeforeDueDate));
}

/**
 * Builds Up a Lower Disposition Tier based on the provided Dispositions and the selected one
 */
const getDispositionSubTier = (
    dispositions: Array<DataType<DispositionV1Attributes>>,
    selectedDisposition: number
): Array<DataType<DispositionV1Attributes>> => {
    const dispositionTier = [];
    for (const disposition of dispositions) {
        if (disposition.attributes?.parent_id !== selectedDisposition) continue;
        dispositionTier.push(disposition);
    }
    return dispositionTier;
};

const EXCLUDED_KEY_VALUES: Partial<Record<keyof TicketV1Attributes, (value: any) => boolean>> = {
    requested_by: (value: any) => {
        return value !== null && value > 0;
    },
};

/**
 * Excludes properties with corrupted values from the provided payload.
 *
 * @param payload - Partial object of TicketV1Attributes.
 * @returns A new object with properties whose values don't match the excluded values.
 */
const filterCorruptedValues = (payload: Partial<TicketV1Attributes>) => {
    const entries = Object.entries(payload) as [keyof TicketV1Attributes, keyof TicketV1Attributes][];
    return Object.fromEntries(
        entries.filter(([key, value]) => {
            const validator = EXCLUDED_KEY_VALUES[key];
            return validator ? validator(value) : true;
        })
    );
};

/**
 * Fetches All Required Modal Data, build the disposition Tree
 */
export const fetchModalInfo = createAsyncThunk("ticketEditInfoModal/fetchInfoModal", async (dispositionId: number) => {
    // Build Disposition Tree
    const dispositions = [...(await ticketingService.getDispositions())]; // Clone

    // Find Disposition Chain belonging to the Given Disposition
    let currentTierThreeDisposition = dispositions.filter((disposition) => Number(disposition.id) === dispositionId)[0];
    let currentTierTwoDisposition = dispositions.filter(
        (disposition) => currentTierThreeDisposition.attributes.parent_id === Number(disposition.id)
    )[0];
    let currentTierOneDisposition = dispositions.filter(
        (disposition) => currentTierTwoDisposition.attributes.parent_id === Number(disposition.id)
    )[0];

    // Reorganize dispositions if there's missing ones
    if (!currentTierOneDisposition && currentTierTwoDisposition && currentTierThreeDisposition) {
        currentTierOneDisposition = currentTierTwoDisposition;
        currentTierTwoDisposition = currentTierThreeDisposition; // @ts-ignore
        currentTierThreeDisposition = null;
    }

    // Tier One/Two/Three Options
    const dispositionTierOneOptions = [];
    for (const disposition of dispositions) {
        if (disposition.attributes?.parent_id) continue;
        dispositionTierOneOptions.push(disposition);
    }
    const dispositionTierTwoOptions = getDispositionSubTier(dispositions, Number(currentTierOneDisposition.id));
    const dispositionTierThreeOptions = getDispositionSubTier(dispositions, Number(currentTierTwoDisposition.id));

    return {
        selectedTierOneOption: currentTierOneDisposition,
        selectedTierTwoOption: currentTierTwoDisposition,
        selectedTierThreeOption: currentTierThreeDisposition,
        dispositions,
        dispositionTierOneOptions,
        dispositionTierTwoOptions,
        dispositionTierThreeOptions,
    };
});

interface validateReservationThunk {
    reservationId: number | null;
    unitId: number | null;
}

/**
 * Validate if ticket should have ReservationID based on Dispositions selected
 * @param dispositions
 * @param reservationId
 * @param unitId
 */
export const validateReservationIdAndDispositions = createAsyncThunk<any, validateReservationThunk>(
    "ticketEditInfoModal/validateReservationIdAndDispositions",
    async ({reservationId, unitId}, {getState, rejectWithValue}): Promise<boolean> => {
        const state = (getState() as RootState).ticketEditInfoModal;
        const selectedDispositions = [state.selectedTierOneOption, state.selectedTierTwoOption, state.selectedTierThreeOption].filter(
            (d) => !isNil(d)
        );
        const restrictedDispositions: number[] = [
            DispositionsEnum.OWNER_CLEAN,
            DispositionsEnum.CANCELLED_RESERVATION,
            DispositionsEnum.UNDERFUNDED_RESERVATION,
            DispositionsEnum.FORCED_MOVE,
            DispositionsEnum.RESERVATION_FEES,
            DispositionsEnum.GUEST_DAMAGE,
            DispositionsEnum.APP_CLAIM,
        ];

        if (isNumber(reservationId)) {
            // validate if reservation exists
            let reservation;
            try {
                reservation = await reservationService.getReservationByLegacyID(reservationId);
            } catch (error) {
                throw rejectWithValue(getErrorMessage(error));
            }
            if (isNil(reservation)) throw rejectWithValue("Reservation entered not found.");

            // validate if unit id from reservation match with unit id in the ticket
            if (!isNil(unitId) && reservation?.attributes.legacy_unit_id !== unitId)
                throw rejectWithValue("Reservation entered is not from the ticket unit.");
        } else {
            // validate if reservation id is required when is null
            if (!isEmpty(intersection(selectedDispositions, restrictedDispositions)))
                throw rejectWithValue("Reservation ID is required for this disposition.");
        }

        // if there is no errors, return as valid
        return true;
    }
);

/**
 * Updates Ticket fields ( Disposition, Requested By, Recurrence )
 */
export const patchTicketInfo = createAsyncThunk("ticketEditInfoModal/updateTicket", async (ticketId: number, {getState}) => {
    const state = (getState() as RootState).ticketEditInfoModal;

    // build payload to update
    const dispositionId = state.selectedTierThreeOption || state.selectedTierTwoOption || state.selectedTierOneOption;

    const patchPayload: Partial<TicketV1Attributes> = filterCorruptedValues({
        requested_by: state.selectedRequestedBy,
        disposition_id: Number(dispositionId),
        reservation_id: state.selectedReservationId || null,
        frequency: state.selectedRecurrenceOption ? state.selectedFrequencyOfRecurrenceOption : null,
        frequency_start_date: state.selectedRecurrenceOption ? moment(state.selectedFrequencyStartDate).toISOString() : null,
        recurring_reopen_type: state.selectedRecurrenceOption ? state.selectedReopenBasedOn : null,
        recurring_days_before_due_date: state.selectedRecurrenceOption ? state.selectedRecurringBeforeDueDate : null,
        refund_request_address: state.guestRefundData.refundRequestAddress,
        refund_request_city: state.guestRefundData.refundRequestCity,
        refund_request_first_name: state.guestRefundData.refundRequestFirstName,
        refund_request_last_name: state.guestRefundData.refundRequestLastName,
        refund_request_rent_amount: state.guestRefundData.refundRequestRentAmount,
        refund_request_state: state.guestRefundData.refundRequestState,
        refund_request_total_amount: state.guestRefundData.refundRequestTotalAmount,
        refund_request_zip: state.guestRefundData.refundRequestZip,
    });

    try {
        await ticketingService.patchTicket(ticketId, patchPayload);
        enqueueSuccess({logInfo: TICKET_INFO_UPDATE, data: patchPayload});
    } catch (error) {
        enqueueError({logInfo: TICKET_INFO_UPDATE, error: Error(getErrorMessage(error))});
    }
});

/**
 * Default Values for Recurrence, these are set up once recurrence changes from Non-recurrent to Recurrent.
 */
const defaults = {
    frequencyOfRecurrence: 30,
    reopenBasedOn: "last_open_date",
    recurringBeforeDueDate: 30,
};

export interface ITicketEditInfoModalState {
    isLoadingFetch: boolean;
    isCustomFrequencyOfRecurrence: boolean;
    isCustomDueDate: boolean;
    isCustomReopenBasedOn: boolean;
    validationReservationErrorMsg: string;

    selectedRequestedBy: number | null;
    selectedReservationId: number | null;
    selectedReopenAfterDueDate: number;
    selectedRecurrenceOption: boolean;
    selectedFrequencyOfRecurrenceOption: number | null;
    selectedReopenBasedOn: string | null;
    selectedReopenBasedOnDate: string | null;
    selectedRecurringBeforeDueDate: number | null;
    selectedFrequencyStartDate: string | null;

    // Dispositions
    dispositions: Array<DataType<DispositionV1Attributes>>;
    selectedTierOneOption: number | null;
    selectedTierTwoOption: number | null;
    selectedTierThreeOption: number | null;
    dispositionTierOneOptions: Array<DataType<DispositionV1Attributes>>;
    dispositionTierTwoOptions: Array<DataType<DispositionV1Attributes>>;
    dispositionTierThreeOptions: Array<DataType<DispositionV1Attributes>>;

    // Refund
    guestRefundData: {
        refundRequestAddress?: string | null;
        refundRequestCity?: string | null;
        refundRequestFirstName?: string | null;
        refundRequestLastName?: string | null;
        refundRequestRentAmount?: number | null;
        refundRequestState?: string | null;
        refundRequestTotalAmount?: number | null;
        refundRequestZip?: string | null;
    };
}

const ticketEditInfoModalSlice = createSlice({
    name: "ticketEditInfoModal",
    initialState: {
        isLoadingFetch: true,
        isCustomFrequencyOfRecurrence: false,
        isCustomDueDate: false,
        isCustomReopenBasedOn: false,
        validationReservationErrorMsg: "",

        // Dispositions
        dispositions: [],
        selectedTierOneOption: null,
        selectedTierTwoOption: null,
        selectedTierThreeOption: null,
        dispositionTierOneOptions: [],
        dispositionTierTwoOptions: [],
        dispositionTierThreeOptions: [],

        selectedRequestedBy: null,
        selectedRecurringBeforeDueDate: null,
        selectedReopenBasedOn: null,
        selectedReopenBasedOnDate: null,
        selectedRecurrenceOption: false,
        selectedFrequencyOfRecurrenceOption: null,
        selectedReopenAfterDueDate: 0,
        selectedReservationId: null,
        selectedFrequencyStartDate: null,
        guestRefundData: {},
    } as ITicketEditInfoModalState,
    reducers: {
        setRecurrentOption(state: ITicketEditInfoModalState, action) {
            state.selectedRecurrenceOption = action.payload;

            // If Set as Not Recurring we skip default
            if (!action.payload) return;

            state.selectedFrequencyOfRecurrenceOption = defaults.frequencyOfRecurrence;
            state.selectedReopenBasedOn = defaults.reopenBasedOn;
            state.selectedRecurringBeforeDueDate = defaults.recurringBeforeDueDate;
            state.isCustomReopenBasedOn = isCustomReopenBasedOn(state.selectedReopenBasedOn);
            state.isCustomDueDate = isCustomDueDate(state.selectedRecurringBeforeDueDate);
        },
        setFrequencyOfRecurrenceOption(state: ITicketEditInfoModalState, action) {
            state.selectedFrequencyOfRecurrenceOption = Number(action.payload);
            state.isCustomFrequencyOfRecurrence = !Object.keys(TicketEntity.frequencyOfRecurrence).includes(String(action.payload));
        },
        setFrequencyOfRecurrenceOptionCustom(state: ITicketEditInfoModalState, action) {
            state.selectedFrequencyOfRecurrenceOption = Number(action.payload);
        },
        setReservationId(state: ITicketEditInfoModalState, action) {
            state.selectedReservationId = isNumber(action.payload) || !isNaN(parseInt(action.payload)) ? Number(action.payload) : null;
        },
        setReopenBasedOn(state: ITicketEditInfoModalState, action) {
            state.selectedReopenBasedOn = action.payload;
            state.isCustomReopenBasedOn = isCustomReopenBasedOn(action.payload);
        },
        setRecurringBeforeDueDate(state: ITicketEditInfoModalState, action) {
            state.selectedRecurringBeforeDueDate = Number(action.payload);
            state.isCustomDueDate = isCustomDueDate(state.selectedRecurringBeforeDueDate);
        },
        setSelectedTierOneOption(state: ITicketEditInfoModalState, action) {
            const selectedDisposition = Number(action.payload);
            state.selectedTierOneOption = selectedDisposition;
            state.selectedTierTwoOption = null;
            state.selectedTierThreeOption = null;
            state.dispositionTierThreeOptions = [];
            state.dispositionTierTwoOptions = getDispositionSubTier(state.dispositions, selectedDisposition);
        },
        setSelectedTierTwoOption(state: ITicketEditInfoModalState, action) {
            const selectedDisposition = Number(action.payload);
            state.selectedTierTwoOption = selectedDisposition;
            state.selectedTierThreeOption = null;
            state.dispositionTierThreeOptions = getDispositionSubTier(state.dispositions, selectedDisposition);
        },
        setSelectedTierThreeOption(state: ITicketEditInfoModalState, action) {
            state.selectedTierThreeOption = Number(action.payload);
        },
        setSelectedFrequencyStartDate(state: ITicketEditInfoModalState, action) {
            state.selectedFrequencyStartDate = String(action.payload);
        },
        setSelectedRequestedBy(state: ITicketEditInfoModalState, action) {
            state.selectedRequestedBy = Number(action.payload);
        },
        setValidationReservationErrorMsg(state: ITicketEditInfoModalState, action) {
            state.validationReservationErrorMsg = action.payload;
        },
        setGuestRefundData(state: ITicketEditInfoModalState, action) {
            state.guestRefundData = {...action.payload};
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchModalInfo.pending, (state: ITicketEditInfoModalState) => {
                state.isLoadingFetch = true;
            })
            // @ts-ignore
            .addCase(fetchModalInfo.fulfilled, (state: ITicketEditInfoModalState, {payload}: {payload: fetchInfoPayload}) => {
                state.isLoadingFetch = false;
                state.dispositions = payload.dispositions;
                state.dispositionTierOneOptions = payload.dispositionTierOneOptions;
                state.dispositionTierTwoOptions = payload.dispositionTierTwoOptions;
                state.dispositionTierThreeOptions = payload.dispositionTierThreeOptions;
                state.selectedTierOneOption = payload.selectedTierOneOption?.id ? Number(payload.selectedTierOneOption?.id) : null;
                state.selectedTierTwoOption = payload.selectedTierTwoOption?.id ? Number(payload.selectedTierTwoOption?.id) : null;
                state.selectedTierThreeOption = payload.selectedTierThreeOption?.id ? Number(payload.selectedTierThreeOption?.id) : null;
            })
            .addCase(validateReservationIdAndDispositions.rejected, (state: ITicketEditInfoModalState, {payload}: {payload: any}) => {
                state.validationReservationErrorMsg = payload;
            });
    },
});

export const {
    setRecurrentOption,
    setFrequencyOfRecurrenceOption,
    setFrequencyOfRecurrenceOptionCustom,
    setReservationId,
    setReopenBasedOn,
    setRecurringBeforeDueDate,
    setSelectedTierOneOption,
    setSelectedTierTwoOption,
    setSelectedTierThreeOption,
    setSelectedFrequencyStartDate,
    setSelectedRequestedBy,
    setValidationReservationErrorMsg,
    setGuestRefundData,
} = ticketEditInfoModalSlice.actions;
export default ticketEditInfoModalSlice.reducer;
