/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import styles from "./TicketEditComments.module.scss"
import { CommentForm } from "components/comment-form/CommentForm";
import { TicketingService } from "services/TicketingService";
import { enqueueError, enqueueSuccess, getErrorMessage } from "utils/message";
import DOMPurify from "dompurify";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { fetchHistory } from "store/ticketEditHistorySlice";
import { EVENT_LOG_DATA, VisibilityStatusEnum, OWNER_COMMENT_PLACEHOLDER, TaggedUsernameRegex } from "appConstants";
import { fetchTicket, silentLoading } from "store/ticketEditSlice";
import classNames from "classnames";

const ticketingService = TicketingService.getInstance();

const { TICKET_COMMENT_ADD } = EVENT_LOG_DATA;

interface TicketEditCommentsProps {
    ticketId: number
}
export const TicketEditComments: React.FC<TicketEditCommentsProps> = ({ ticketId }) => {
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const acceptBtnLabelMap = new Map<VisibilityStatusEnum, string>([
        [VisibilityStatusEnum.OWNER, "Comment to Owner"],
        [VisibilityStatusEnum.INTERNAL, "Comment"]
    ]);
    const placeholderMap = new Map<VisibilityStatusEnum, string>([
        [VisibilityStatusEnum.OWNER, OWNER_COMMENT_PLACEHOLDER],
        [VisibilityStatusEnum.INTERNAL, "Write a comment..."]
    ]);

    const silentReload = async () => {
        dispatch(silentLoading());
        dispatch(fetchTicket(ticketId));
    };

    const getUserTags = (comment: string) => new Set([...comment.matchAll(new RegExp(TaggedUsernameRegex.AT_EXCLUDED, 'g'))].map(match => match[1]));

    const onCommentAccept = async (text: string, visibility: VisibilityStatusEnum) => {
        try {
            const request = {
                "ticket_id": ticketId,
                "note": DOMPurify.sanitize(text),
                "external": Number(visibility === VisibilityStatusEnum.OWNER)
            };

            await ticketingService.addComment(request);

            // Reload Ticket followers component
            const hasTaggedUsers = getUserTags(request.note).size
            if (hasTaggedUsers) silentReload();

            dispatch(fetchHistory(ticketId));
            enqueueSuccess({ logInfo: TICKET_COMMENT_ADD, data: request });
        } catch (error) {
            enqueueError({ logInfo: TICKET_COMMENT_ADD, error: Error(getErrorMessage(error)) });
        }
    };

    return (
        <div className={classNames(styles.content, 'no-print')}>
            <CommentForm acceptBtnLabel={acceptBtnLabelMap} onAccept={onCommentAccept} maxLength={5000} placeholder={placeholderMap} />
        </div>
    );
}
