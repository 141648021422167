import { useEffect, useState } from "react";
import { Typography, List, ListItem } from "@mui/material";
import { ConfirmDialog } from "components/confirm-dialog/ConfirmDialog";

interface ThirdPartyCookiesGuardProps {
    children: React.ReactNode;
}

export const ThirdPartyCookiesGuard: React.FC<ThirdPartyCookiesGuardProps> = ({ children }) => {
    const [cookiesBlocked, setCookiesBlocked] = useState<boolean | null>(null);
    const [browser, setBrowser] = useState("Other");

    /**
     * Checks if third-party cookies are enabled by attempting to set a test cookie.
     * 
     * @returns {boolean} - Returns `true` if third-party cookies are enabled, `false` otherwise.
     */
    const checkThirdPartyCookies = () => {
        try {
            document.cookie = "crossSiteCheckCookie=1; SameSite=None; Secure";
            return document.cookie.includes("crossSiteCheckCookie");
        } catch {
            return false;
        }
    };

    /**
     * Detects the user's browser based on the user agent string.
     * 
     * @returns {string} - The detected browser name.
     */
    const detectBrowser = () => {
        const userAgent = navigator.userAgent;
        const isIOS = /iPhone|iPad|iPod/i.test(userAgent);
        const isAndroid = /Android/i.test(userAgent);
    
        if (/CriOS/i.test(userAgent)) return "Chrome iOS";
        if (/Chrome/i.test(userAgent) && isAndroid) return "Chrome Android";
        if (/Chrome/i.test(userAgent)) return "Chrome";
        if (/Firefox/i.test(userAgent)) return "Firefox";
        if (/Edge/i.test(userAgent)) return "Edge";
        if (/Safari/i.test(userAgent) && !/Chrome/i.test(userAgent)) {
            return isIOS ? "Safari iOS" : "Safari";
        }
    
        return "Other";
    };

    // Run the cookie check on mount
    useEffect(() => {
        const enabled = checkThirdPartyCookies();
        setCookiesBlocked(!enabled);
        setBrowser(detectBrowser());
    }, []);
    
    /**
     * Instructions for enabling third-party cookies based on the detected browser.
     */
    const browserInstructions: Record<string, string[]> = {
        "Chrome iOS": [
            "Open the Settings app on your iPhone or iPad.",
            "Go to Apps.",
            "Scroll down and select Chrome.",
            "Enable 'Allow Cross-Website Tracking'.",
        ],
        "Chrome Android": [
            "Open Chrome and tap the three-dot menu in the top-right corner.",
            "Go to Settings > Site settings.",
            "Select Cookies.",
            "Choose 'Allow cookies'.",
        ],
        Chrome: [
            "Open Chrome and tap the three-dot menu in the top-right corner.",
            "Go to Settings.",
            "Navigate to Privacy and security > Third-party Cookies.",
            "Scroll down and select 'Allow third-party cookies'.",
        ],
        Safari: [
            "Open Safari and click on the Safari menu.",
            "Choose Settings and then Privacy.",
            "Deselect 'Prevent cross-site tracking.'",
            "Then, go to the Advanced tab.",
            "Ensure 'Block all cookies' is not selected."
        ],
        "Safari iOS": [
            "Open your device settings.",
            "Select 'Safari'.",
            "Ensure both 'Block All Cookies' and 'Prevent Cross-Site Tracking' are disabled."
        ],
        Edge: [
            "Go to Settings > Cookies and site permissions.",
            "Under 'Cookies and site data', ensure that 'Block third-party cookies' is disabled."
        ],
        Firefox: [
            "Go to Settings > Privacy & Security.",
            "Under 'Enhanced Tracking Protection', set it to 'Standard' or 'Custom' (disable 'Cross-Site Tracking Cookies')."
        ],
        Other: [
            "Check your browser's privacy settings and allow third-party cookies."
        ]
    };

    // Return null until cookies check
    if (cookiesBlocked === null) return null;

    return (
        <>
            { cookiesBlocked 
            ? 
            <>
                <ConfirmDialog 
                    visible={true}
                    onClose={() => window.location.reload()}
                    title="Third-Party Cookies Disabled"
                    cancelLabel="Retry"
                    sx={{
                        "& .MuiPaper-root": {
                            margin: 0,
                        }
                    }}
                >
                    <Typography>
                        We detected you don't have Third-Party Cookies enabled on your browser. Please enable them before accessing this site:
                    </Typography>

                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                        Instructions for {browser}:
                    </Typography>
                    <List>
                        {browserInstructions[browser]?.map((step, index) => (
                            <ListItem key={index}>• {step}</ListItem>
                        ))}
                    </List>
                </ConfirmDialog>
            </>
            : children
            }
        </>
    );
};
