import React, { useState } from "react";
import { Spinner } from "components/spinner/Spinner";
import classNames from "classnames";
import styles from "./LoaderButton.module.scss";
import { datadogLogs } from "@datadog/browser-logs";

interface LoaderButtonProps {
    variant?: "primary" | "secondary" | "primary-inverse";
    size?: "normal" | "small";
    onClick: (event?: React.MouseEvent<HTMLButtonElement>) => void;
    children: React.ReactNode;
    className?: string;
    id?: string;
    disabled?: boolean;
}

export const LoaderButton: React.FC<LoaderButtonProps> = ({onClick, variant = "primary", children, className, id, disabled, size}: LoaderButtonProps) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleOnClick = async () => {
        setIsLoading(true);
        try {
            await onClick();
        } catch(e) {
            if (!(e instanceof Error)) throw e;
            datadogLogs.logger.error("LoaderButton.handleOnClick", {}, e);
        }
        setIsLoading(false);
    }

    const isActive = !isLoading && !disabled;

    return (
        <button
            id={id}
            data-testid={id}
            disabled={!isActive}
            onClick={handleOnClick}
            className={classNames(
                styles['button'],
                "button",
                `button-${variant}`,
                {"active": isActive},
                size,
                className,
                'no-print'
            )}
        >
            {isLoading && <Spinner/>}{children}
        </button>
    );
}
