import React, { useEffect } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch, { SwitchProps } from '@material-ui/core/Switch';
import './Toggle.scss';

interface Props extends SwitchProps {
}

const StylishSwitch = ({ ...props }: Props) => {
  return (
    <Switch
      disableRipple
      {...props}
    />
  );
};

interface CheckboxProps {
    id?: string
    isToggled?: boolean;
    onToggle?: (isChecked: boolean) => void;
}

export default function Toggle({ isToggled = false, onToggle, id = "" }: CheckboxProps) {
  const [toggleStatus, setToggleStatus] = React.useState(isToggled);

  useEffect(() => {
    setToggleStatus(isToggled);
  }, [isToggled]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newToggleStatus = !toggleStatus;
    setToggleStatus(newToggleStatus);
    if(onToggle) onToggle(event.target.checked);
  };

  return (
      <FormControlLabel
        control={<StylishSwitch id={id} checked={toggleStatus} onChange={handleChange} name="checked" />}
        label={toggleStatus ? "Active" : "Disabled"}
      />
  );
}
