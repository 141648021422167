import {createAsyncThunk, createSlice, isAnyOf} from "@reduxjs/toolkit";
import {DEFAULT_SEARCH_PAGINATION_VALUES, SearchOrderKeysEnum} from "appConstants";
import {AlertMessageParams} from "components/alert-message/AlertMessage";
import union from "lodash/union";
import {ConnectService} from "services/ConnectService";
import {TicketingService} from "services/TicketingService";
import {UnitService} from "services/UnitService";
import type {UnitV2Attributes, UserConnectAttributes} from "typing/dto";
import type {
    GetTicketsResponse,
    MetaPagination,
    PageQueryParams,
    QueryParams,
    SearchOrderByQueryParams,
    SearchSelectedFilterValues,
    SearchSortOrder,
    TicketingServiceFilter,
    TicketsDataType,
} from "typing/request";
import {getErrorMessage} from "utils/message";

// Service Singletons
const ticketingService = TicketingService.getInstance();
const connectService = ConnectService.getInstance();
const unitService = UnitService.getInstance();

export const fetchTickets = createAsyncThunk<GetTicketsResponse, QueryParams<TicketingServiceFilter, string>, {rejectValue: string}>(
    "ticketSearch/fetchTickets",
    async (data, {rejectWithValue}) => {
        try {
            const {page, filter, sort, include} = {...data};
            const response = await ticketingService.getTickets({page, filter, sort, include}, {requestCancellation: true});
            return response ?? {data: []};
        } catch (error) {
            throw rejectWithValue(getErrorMessage(error));
        }
    }
);
export const fetchUsers = createAsyncThunk<UserConnectAttributes[], string, {rejectValue: string}>(
    "ticketSearch/fetchUsers",
    async (ids: string, {rejectWithValue}): Promise<UserConnectAttributes[]> => {
        const uniqueIds = union(ids.split(",").filter((id) => Boolean(id))).join();
        try {
            return connectService.getUsersById(uniqueIds) ?? [];
        } catch (error) {
            throw rejectWithValue(getErrorMessage(error));
        }
    }
);

export const fetchUnitCodes = createAsyncThunk<UnitV2Attributes[], string, {rejectValue: string}>(
    "ticketSearch/fetchUnitCodes",
    async (ids: string, {rejectWithValue}): Promise<UnitV2Attributes[]> => {
        const uniqueIds = union(ids.split(",").filter((id) => Boolean(id))).join();
        try {
            return unitService.getUnitsByLegacyId(uniqueIds) ?? [];
        } catch (error) {
            throw rejectWithValue(getErrorMessage(error));
        }
    }
);

export interface ITicketSearchState {
    orderBy: SearchOrderByQueryParams;
    selectedFilterValues: SearchSelectedFilterValues;
    isLoading: boolean;
    pagination: null | MetaPagination;
    page: PageQueryParams;
    reload: boolean;
    tickets: TicketsDataType[];
    users: UserConnectAttributes[];
    units: UnitV2Attributes[];
    alertMessageParams: AlertMessageParams;
}

const ticketSearchSlice = createSlice({
    name: "ticketSearch",
    initialState: {
        orderBy: {},
        selectedFilterValues: {},
        isLoading: true,
        pagination: null,
        reload: false,
        page: {number: DEFAULT_SEARCH_PAGINATION_VALUES.NUMBER, limit: DEFAULT_SEARCH_PAGINATION_VALUES.LIMIT},
        tickets: [],
        users: [],
        units: [],
        alertMessageParams: {},
    } as ITicketSearchState,
    reducers: {
        setPage: (state: ITicketSearchState, action) => {
            state.page = action.payload;
        },
        setReload: (state: ITicketSearchState) => {
            state.reload = !state.reload;
        },
        upsertOrderBy: (state: ITicketSearchState, action) => {
            const [[property, order]] = Object.entries(action.payload) as [SearchOrderKeysEnum, SearchSortOrder][];

            if (property in state.orderBy) {
                state.orderBy[property] = order;
            } else {
                state.orderBy = {...state.orderBy, [property]: order};
            }
        },
        setUsers: (state: ITicketSearchState, action) => {
            state.users = action.payload;
        },
        setUnits: (state: ITicketSearchState, action) => {
            state.units = action.payload;
        },
        setSelectedFilterValues: (state: ITicketSearchState, action) => {
            state.selectedFilterValues = action.payload;
        },
        deleteOrderBy: (state: ITicketSearchState, action: {payload: SearchOrderKeysEnum}) => {
            const orderBy = Object.assign({}, state.orderBy);
            delete orderBy[action.payload];
            state.orderBy = orderBy;
        },
        openErrorAlert: (state: ITicketSearchState, action) => {
            state.alertMessageParams.visible = true;
            state.alertMessageParams.text = action.payload;
            state.alertMessageParams.severity = "error";
        },
        closeAlert: (state: ITicketSearchState) => {
            state.alertMessageParams.visible = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTickets.pending, (state: ITicketSearchState) => {
                state.isLoading = true;
            })
            .addCase(
                fetchTickets.fulfilled,
                (
                    state: ITicketSearchState,
                    {
                        payload,
                    }: {
                        payload: GetTicketsResponse;
                    }
                ) => {
                    state.isLoading = false;
                    state.tickets = payload.data;
                    state.pagination = payload.meta || null;
                }
            )
            .addCase(fetchUsers.pending, (state: ITicketSearchState) => {
                state.isLoading = true;
            })
            .addCase(fetchUsers.fulfilled, (state: ITicketSearchState, {payload}: {payload: UserConnectAttributes[]}) => {
                state.isLoading = false;
                state.users = payload;
            })
            .addCase(fetchUnitCodes.pending, (state: ITicketSearchState) => {
                state.isLoading = true;
            })
            .addCase(fetchUnitCodes.fulfilled, (state: ITicketSearchState, {payload}: {payload: UnitV2Attributes[]}) => {
                state.isLoading = false;
                state.units = payload;
            })
            .addMatcher(isAnyOf(fetchUnitCodes.rejected, fetchUsers.rejected, fetchTickets.rejected), (state: ITicketSearchState) => {
                state.isLoading = false;
            });
    },
});

export const {setPage, setReload, setUsers, setUnits, upsertOrderBy, setSelectedFilterValues, deleteOrderBy, openErrorAlert, closeAlert} =
    ticketSearchSlice.actions;

export default ticketSearchSlice.reducer;
