import {ImplicitFlowService} from "./ImplicitFlowService";
import {env} from "core/env";
import {HandleServiceError} from "decorators/service";
import isEmpty from "lodash/isEmpty";
import qs from "qs";
import {ConnectUnitAttributes, UserConnectAttributes} from "typing/dto";
import type {ConnectServiceFilter, CustomRequestConfiguration, DataType, GetResponse, PageQueryParams} from "typing/request";

export class ConnectService extends ImplicitFlowService {
    private static baseUrl = env.REACT_APP_CONNECT_SERVICE_URL;
    private static service: ConnectService;
    private _currentUser: UserConnectAttributes | null;

    constructor() {
        super(ConnectService.baseUrl);
        this._currentUser = null;
    }

    /**
     * Get Singleton Instance
     */
    public static getInstance(): ConnectService {
        if (!ConnectService.service) ConnectService.service = new ConnectService();
        return ConnectService.service;
    }

    /**
     * Get Unit Entity based on it's id
     */
    @HandleServiceError()
    public async getConnectUnitByLegacyId(legacyUnitId: number): Promise<ConnectUnitAttributes | null> {
        // Early return if legacyUnitId is not provided
        if (!legacyUnitId) return null;

        const axios = await this.axiosInstance();
        const {data: response} = await axios.get(`${this.apiUrl}/units/${legacyUnitId}`);

        // early return if it's none
        if (!response?.data) return null;

        // append id to attributes for easier handling
        response.data.attributes.id = response.data.id;
        return response?.data;
    }

    /**
     * Fetches user details by their IDs.
     *
     * This method takes a string of IDs, retrieves the associated user data,
     * and maps it to an array of `UserConnectAttributes` objects. Each object
     * includes the user's attributes and ID.
     *
     * @param {string} ids - A comma-separated string of user IDs.
     * @returns {Promise<UserConnectAttributes[]>} A promise that resolves to an array of user attributes.
     */
    public async getUsersById(ids: string): Promise<UserConnectAttributes[]> {
        if (!ids) return [];
        const users = await this.getUsers({id: ids});
        return users.map((user: DataType<UserConnectAttributes>) => ({
            ...user.attributes,
            id: user.id,
        }));
    }

    /**
     * Get all users based on filter
     * @param filter
     * @param page
     */
    @HandleServiceError()
    public async getUsers(
        filter?: ConnectServiceFilter,
        page?: PageQueryParams,
        customRequestConfig: CustomRequestConfiguration = {}
    ): Promise<DataType<UserConnectAttributes>[]> {
        const axios = await this.axiosInstance(customRequestConfig);
        const params = {
            page,
            filter,
        };
        const {data: response} = await axios.get(`${this.apiUrl}/logins`, {
            params,
            paramsSerializer: (params) => {
                return qs.stringify(params, {arrayFormat: "brackets"});
            },
        });
        return (
            response?.data.map((user: DataType<UserConnectAttributes>) => ({
                ...user,
                attributes: {
                    ...user.attributes,
                    id: Number(user.id),
                },
            })) || []
        );
    }

    @HandleServiceError()
    /**
     * Retrieves user details by their unique ID.
     *
     * This method handles errors using the `@HandleServiceError()` decorator, which
     * ensures that errors are properly logged or managed as defined in the decorator's logic.
     *
     * @param {number} userId - The unique identifier of the user.
     * @returns {Promise<GetResponse<UserConnectAttributes> | null>} A promise resolving to the user's details
     * if the operation is successful, or `null` in case of an error or if the `userId` is invalid.
     */
    public async getUserById(userId: number): Promise<GetResponse<UserConnectAttributes> | null> {
        return this.getUserByIdData(userId);
    }

    /**
     * Fetches raw user data by their unique ID from an external API.
     *
     * This method directly interacts with the API endpoint to retrieve user details.
     * It validates the input `userId` and returns `null` if the `userId` is invalid.
     * The method also parses and processes the response data to ensure consistency.
     *
     * @param {number} userId - The unique identifier of the user.
     * @returns {Promise<GetResponse<UserConnectAttributes> | null>} A promise resolving to the user details
     * fetched from the API, or `null` if the `userId` is invalid.
     *
     * @throws {Error} Throws an error if the API call fails or if the response format is unexpected.
     */
    public async getUserByIdData(userId: number): Promise<GetResponse<UserConnectAttributes> | null> {
        if (!userId) return null;
        const axios = await this.axiosInstance();
        const {data: response} = await axios.get(`${this.apiUrl}/logins/${userId}`);
        response.data.attributes.id = parseInt(response.data.id);
        return response;
    }

    @HandleServiceError({silentFail: true})
    /**
     * Retrieves user details by their unique ID with silent error handling.
     *
     * This method is similar to `getUserById`, but it uses the `@HandleServiceError({ silentFail: true })`
     * decorator to suppress errors during execution. If an error occurs, it logs the error and
     * returns `null` without interrupting the program flow.
     *
     * @param {number} userId - The unique identifier of the user.
     * @returns {Promise<GetResponse<UserConnectAttributes> | null>} A promise resolving to the user's details
     * if the operation is successful, or `null` in case of an error or invalid `userId`.
     */
    public async getSilentUserById(userId: number): Promise<GetResponse<UserConnectAttributes> | null> {
        return this.getUserByIdData(userId);
    }

    /**
     * Sets the logged user ID if there is none.
     * @returns
     */
    @HandleServiceError()
    public async setCurrentUser(): Promise<void> {
        if (this.currentUser) return;
        const email = ConnectService.getAuthenticatedUser()?.email;
        const users = email ? await this.getUsers({email}) : [];
        this._currentUser = isEmpty(users) ? null : {...users[0].attributes, id: Number(users[0].id)};
    }

    public get currentUser(): UserConnectAttributes | null {
        return this._currentUser;
    }

    public get currentUserId(): number {
        return this._currentUser ? Number(this._currentUser.id) : 0;
    }
}
